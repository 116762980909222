const ArrayHelps = {
  findIndexInData: function(data, property, value){
    var result = -1;
    data.some(function (item, i) {
      if (item[property] === value) {
          result = i;
          return true;
      }
    });
    return result;
  },
  updateOrCreate: function(item, data){
    var output = {};
    Object.keys(item).forEach(key => {
      if (item[key] instanceof Object) {
        output[key] = Object.assign({}, item[key], data[key]);
      } else {
        output[key] = item[key];
      }
    });
    return output;
  },

  updateOrCreate2: function(target, source){
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.updateOrCreate2(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return target;
  },

  isObject: function(item) {
    return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
  },


  
  clone: function(obj){
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }
}

export default ArrayHelps;
