import React, {
    Component
  } from 'react';
  import { Form, Button, Modal } from 'antd';
  import 'antd/dist/antd.css';
  
  const { confirm } = Modal;

  class RemoveElement extends Component {
    onChange = () => {
        const element = this.props.element;
        this.props.triggerRemoveElement(element);
    };

    onConfirm = e => {
        var self = this;
        confirm({
            title: 'Bạn có chắc chắn muốn xoá?',
            content: 'Nội dung sau khi xoá xong sẽ không thể khôi phục',
            onOk() {
                self.onChange();
            },
            onCancel(){}
        });
    }
  
    render(){
      
      return(
        <Form.Item labelAlign='left' wrapperCol={{ span: 24 }}>
          <Button onClick={(e) => this.onConfirm(e)} style={{width: '100%'}} type="danger">Xoá nội dung này</Button>
        </Form.Item>
      )
    }
  }
  
  export default RemoveElement;
  