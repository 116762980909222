import React, {
  Component
} from 'react';

import '../side.sass';
import './sideRight.sass';
import '../../../../constants/style';
import { MdStyle } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { AiOutlineLayout } from "react-icons/ai";
import AddContentSideRight from './addContentSideRight';
import PopupSettingSideRight from './popupSettingSideRight';
import PopupAdvanceSideRight from './popupAdvanceSideRight';
class SidebarRight extends Component {
  constructor(props){
    super(props);
    this.state = {
      tab: this.props.tab
    }
  }

  setActiveTab = value => {
    this.props.triggerEditTab(value);
  }

  render(){
    let className = "active";
    const { tab } = this.props;
    return(
      <div className="sidebar sidebar-right">
        <div className="sizing flex flex-column h-100">
          {/* <div className="sidebar-header table-inline">
            <div onClick={(e) => this.setActiveTab(1)} className={["item table-cell text-center", tab === 1 && className].filter(x => !!x).join(' ')}>
              <div className="icon"><AiOutlineLayout/></div>
              <div className="text">Thiết lập</div>
            </div>
            <div onClick={(e) => this.setActiveTab(2)} className={["item table-cell text-center", tab === 2 && className].filter(x => !!x).join(' ')}>
              <div className="icon"><IoMdSettings/></div>
              <div className="text">Nâng cao</div>
            </div>
            <div onClick={(e) => this.setActiveTab(3)} className={["item table-cell text-center", tab === 3 && className].filter(x => !!x).join(' ')}>
              <div className="icon"><MdStyle/></div>
              <div className="text">POPUP</div>
            </div>
          </div> */}
          <div className="sidebar-body">
            {this.props.tab === 1 && <AddContentSideRight triggerRemoveElement={this.props.elementRemove} triggerEditElement={this.props.elementChanged} element={this.props.element}></AddContentSideRight>}
            {this.props.tab === 2 && <PopupAdvanceSideRight setActiveTab={this.setActiveTab} triggerEditElement={this.props.elementChanged} element={this.props.element}></PopupAdvanceSideRight>}
            {this.props.tab === 3 && <PopupSettingSideRight designInMobile={this.props.designInMobile}></PopupSettingSideRight>}
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarRight;
