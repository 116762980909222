import React, {
  Component
} from 'react';
import ContentEditable from 'react-contenteditable';

class Text extends Component {
  updateContent = result =>{
    let element = this.props.option;
    let data = {
      itemConfig: {
        text: result.target.value
      }
    }
    this.props.changeElement(element, data);
  }

  clickContent(){
    let element = this.props.option;
    this.props.changeElement(element, {});
  }


  render(){
    let data = this.props.option.content.data;
    return(
      <div onClick={(e) => this.clickContent(e)}>
        <label style={{...data.style}}>
          <ContentEditable suppressContentEditableWarning onChange={(e) => this.updateContent(e)} html={data.itemConfig.text}></ContentEditable>
        </label>
      </div>
    );
  }
}

export default Text;
