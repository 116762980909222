import React, {
  Component
} from 'react';
import { Form, Row, Col, Slider, Button } from 'antd';
import 'antd/dist/antd.css';
import { SketchPicker } from 'react-color';

const popover = {
  position: 'relative',
  zIndex: '2',
}
const cover = {
  position: 'absolute',
  top: '5px',
  right: '0px',
  bottom: '0px',
  left: '-125px',
}

class BoxShadow extends Component {
  constructor(props){
    super(props);
    this.state = {
      toggle: false,
      color: this.props.element.content.data.itemConfig.boxShadowColor ? this.props.element.content.data.itemConfig.boxShadowColor : '#000000',
      slider: this.props.element.content.data.itemConfig.boxShadowSlider ? this.props.element.content.data.itemConfig.boxShadowSlider : 0
    }
  }

  openColor(result){
    this.setState({
      toggle: !this.state.toggle
    });
  }

  onChangeSlider = value => {
    this.setState(
      { slider: value },
      this.ChangeValue()
    );
  };

  changeColor = (color) => {
    this.setState(
      { color: color.hex }, () => this.ChangeValue() // wait for update state finish
    );
  };

  ChangeValue(){
    let element = this.props.element;
    let data = {
      style: {
        boxShadow: '0px 0px ' + this.state.slider + 'px ' + this.state.color
      },
      itemConfig: {
        boxShadowColor: this.state.color,
        boxShadowSlider: this.state.slider
      }
    };
    this.props.triggerEditElement(element, data);
  }

  render(){
    let element = this.props.element.content.data.itemConfig;
    let shadowColor = element.boxShadowColor ? element.boxShadowColor : this.state.color;
    return(
      <Form.Item label="Đổ bóng viền" labelCol={{ span: 24 }}>
      <Row>
        <Col span={15}>
          <Slider
            min={0}
            max={30}
            onChange={this.onChangeSlider}
            value={element.boxShadowSlider ? element.boxShadowSlider : 0}
          />
        </Col>
        <Col className="text-right" span={8}>
          <Button onClick={(e) => this.openColor(e)} type="primary" style={{marginLeft: '5px', width: '100%', backgroundColor: shadowColor, borderColor: shadowColor}}>{shadowColor}</Button>
          {this.state.toggle ? <div style={popover}>
            <div style={cover}>
              <SketchPicker color={ element.boxShadowColor } onChange={(e) => this.changeColor(e) }/>
            </div>
          </div> : null}
        </Col>
      </Row>
      </Form.Item>
    )
  }
}

export default BoxShadow;
