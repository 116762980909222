import React, {
  Component
} from 'react';
import {
  Route,
  BrowserRouter as Router
} from "react-router-dom";


import HomeIndex from './containers/home/layoutContainer'

class Routing extends Component {
  render() {
    return (
      <Router>
        <Route exact path="" component={HomeIndex} />
        <Route exact path="/popup/:id/step2"  component={HomeIndex} />
      </Router>
    );
  }
}

export default Routing;
