import React, {
  Component
} from 'react';
import { Form, Input } from 'antd';
import 'antd/dist/antd.css';

const { TextArea } = Input;

class ChangeText extends Component {
  changeText(result){
    let element = this.props.element;
    let data = {
      itemConfig: {
        text: result.target.value,
        placeholder: result.target.value
      }
    }
    this.props.triggerEditElement(element, data);
  }

  render(){
    let element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Văn bản">
        <TextArea onChange={(e) => this.changeText(e)} placeholder="Nhập nội dung cần thay đổi vào đây" rows={4} value={element.text ? element.text : element.placeholder} />
        <div className="mt-20 break"></div>
      </Form.Item>
    )
  }
}

export default ChangeText;
