import React, {
    Component
  } from 'react';

  import Element from '../../site/content/element';
  import Layout from '../../site/content/layout';
  import { PopupApi } from '../../../api/popupApi';

  const style = {
      width: 'auto',
      height: '400px',

  }

  class LoadIndex extends Component {
    constructor(props){
        super(props);
        this.state = {
            error: false
        }
    }

    getAllDataPopup = () => {
        let self = this;
        let config;
        let step2 = window.location.pathname.includes('step2');
        PopupApi.getTemplate().then(res => {
            let data = res.data;
            if (data.success){
                if (!step2){
                    try {
                        config = JSON.parse(data.data.json_content);
                        if ("element" in config){
                            Layout.data(config.layout);
                            Element.data(config.element);
                        }
                        Layout.setStep(1);
                    } catch (error) {
                        Layout.setStep(1);
                    }
                }else{
                    try {
                        config = JSON.parse(data.data.json_content_res);
                        if ("element" in config){
                            Layout.data(config.layout);
                            Element.data(config.element);
                        }
                        Layout.setStep(2);
                    } catch (error) {
                        Layout.setStep(2);
                    }
                }
                PopupApi.setProjectToken(data.data.project_token);
            }
            this.props.loading(false);
        }).catch(function(e) {
            self.setState({
                error: true
            });
            console.error(e); 
        });
    }
    
    componentWillMount(){
        this.getAllDataPopup();
    }


    render(){
        return(
            this.state.error ?
            <div>
                <div style={{width: '100%', height: '100vh'}} className="flex-center flex-wrap">
                    <div>
                    <img style={style} src={require('../../../storages/img/404.gif')} />
                    <div className="box-error" style={{marginTop: '-90px', textAlign: 'center', width: '100%', fontSize: '25px'}}>
                        Không tìm thấy Popup này!
                    </div>
                    </div>
                </div>
            </div> : 
            <div>
                <div style={{width: '100%', height: '100vh'}} className="flex-center">
                    <video width="320" height="320" poster="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=" loop autoPlay muted src={require('../../../storages/file/video.mp4')}></video>
                </div>
            </div>
        );
    }
  }
  
  export default LoadIndex;
  