import React, {
  Component
} from 'react';
import Header from '../../site/header/header';
import {SidebarLeft} from '../../site/sidebar/left/sideLeft';
import SidebarRight from '../../site/sidebar/right/sideRight';
import { Content } from '../../site/content/content';
import Element from '../../site/content/element';
import Layout from '../../site/content/layout';

class HomeIndex extends Component {
  constructor(props){
    super(props);
    this.state = {
      element: null,
      items: Element.init(),
      layout: Layout.init(),
      mobile: false,
      tab: 3
    }
  }

  activeElement(element) {
    this.setState({
      element: element,
      tab: 1
    });
  }

  removeActiveElement(){
    this.setState({
      element: null
    });
  }

  triggerEditTab(tab){
    this.setState({
      tab: tab
    });
  }

  elementChanged(element, data) {
    this.changeElement(element, data);
  }

  elementRemove(element) {
    var res = Element.remove(element);
    this.removeActiveElement();
    this.updateTemplate(res);
  }

  addElement(element){
    var res = Element.add(element);
    var index = Object.keys(res).length - 1;
    this.activeElement(res[index]);
    this.updateTemplate(res);
  }

  changeElement(element, data){ // element target, data replace
    var res = Element.change(element, data);
    this.activeElement(element);
    this.updateTemplate(res);
  }

  updateTemplate(items){
    this.setState({
      items: items
    });
  }

  designInMobileOrPc(val){
    if (val === "mobile"){
      this.setState({
        mobile: true
      });
    }else{
      this.setState({
        mobile: false
      });
    }
  }

  render(){
    return(
      <div className="wrapper">
        <Header designShow={this.designInMobileOrPc.bind(this)}/>
        <div className="container">
          <SidebarLeft
            designInMobile={this.state.mobile}
          />
          <SidebarRight
            element={this.state.element}
            elementChanged={this.elementChanged.bind(this)}
            elementRemove={this.elementRemove.bind(this)}
            designInMobile={this.state.mobile}
            triggerEditTab={this.triggerEditTab.bind(this)}
            tab={this.state.tab}
          />
          <Content
            layout={this.state.layout}
            items={this.state.items}
            designInMobile={this.state.mobile}
            addElement={this.addElement.bind(this)}
            changeElement={this.changeElement.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default HomeIndex;
