import React, {
    Component
  } from 'react';
  import { MdArrowDropDown } from "react-icons/md";
  import { IconContext } from "react-icons";
  import WhenClick from './forms/advance/whenClick';
  class PopupAdvanceSideRight extends Component {
    triggerEditElement = (element, data) => {
      this.props.triggerEditElement(element, data);
      this.props.setActiveTab(2);
    }
    render(){
      let option = this.props.element;
      if (!!!option) return('');
      return(
        <div className="sidebar-item">
          <div className="sidebar-title">
            <IconContext.Provider value={{className: "icon" }}>
              <div>
                <MdArrowDropDown></MdArrowDropDown>
              </div>
            </IconContext.Provider>
            <span>Hành động</span>
          </div>
          <div className="sidebar-content">
            <WhenClick triggerEditElement={this.triggerEditElement} element={option}></WhenClick>
          </div>
        </div>
      );
    }
  }
  
  export default PopupAdvanceSideRight;
  