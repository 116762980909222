var position = {x: 0, y: 0};

const Position = {
  setX: function(position){
    this.get().x = position;
  },

  getX: function(){
    return this.get().x + 'px';
  },

  setY: function(position){
    this.get().y = position;
  },

  getY: function(){
    return this.get().y + 'px';
  },

  get: function(){
    return position;
  },
  style: function(){
    return {
      left: this.getX(),
      top: this.getY(),
      position: 'absolute',
      display: 'block',
      overflow: 'unset'
    };
  }
};


export default Position;
