import React, {
    Component
  } from 'react';
  import { Form, Select } from 'antd';
  import 'antd/dist/antd.css';
  
  const { Option } = Select;

  class DataName extends Component {
    changeData(result){
      let element = this.props.element;
      let data = {
        itemConfig: {
            name: result
        }
      }

      this.props.triggerEditElement(element, data);
    }
  
    render(){
      let element = this.props.element.content.data.itemConfig;
      return(
        <Form.Item label="Tên dữ liệu" labelAlign='left' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Select defaultValue={element.name} value={element.name} onChange={(e) => this.changeData(e)} style={{width: '100%'}}>
            <Option value="name">Tên</Option>
            <Option value="phones">Số điện thoại</Option>
            <Option value="emails">Email</Option>
            <Option value="address">Địa chỉ</Option>
            <Option value="location">Địa điểm</Option>
            <Option value="note">Ghi chú</Option>
            <Option value="message">Lời nhắn</Option>
            <Option value="gender">Giới tính</Option>
            <Option value="source">Nguồn</Option>
            <Option value="anoher">Khác</Option>
          </Select>
        </Form.Item>
      )
    }
  }
  
  export default DataName;
  