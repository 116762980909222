import React, {
  Component
} from 'react';
import { Form, Button } from 'antd';
import 'antd/dist/antd.css';
import { MdFormatBold, MdFormatItalic, MdFormatUnderlined } from "react-icons/md";

const stylesheet = {
  fontSize: '17px'
}
class TextStyle extends Component {
  constructor(props){
    super(props);
    this.state = {
      bold: 'normal',
      italic: 'normal',
      underlined: 'none'
    }
  }

  ChangeValue(value){
    let element = this.props.element;
    let bold = this.state.bold;
    let italic = this.state.italic;
    let underlined = this.state.underlined;

    if (value === 'bold'){
      if (this.state.bold === 'normal'){
        bold = value;
      }else{
        bold = 'normal';
      }
      this.setState({ bold: bold});
    }

    if (value === 'italic'){
      if (this.state.italic === 'normal'){
        italic = value;
      }else{
        italic = 'normal';
      }
      this.setState({ italic: italic});
    }

    if (value === 'underline'){
      if (this.state.underlined === 'none'){
        underlined = value;
      }else{
        underlined = 'none';
      }
      this.setState({ underlined: underlined});
    }

    let data = {
      style: {
        fontStyle: italic,
        fontWeight: bold,
        textDecoration: underlined
      },
      itemConfig: {
        styleFontStyle: italic,
        styleFontWeight: bold,
        styleTextDecoration: underlined
      }
    };
    this.props.triggerEditElement(element, data);
  }

  render(){
    const element = this.props.element.content.data.itemConfig;
    const bold = element.styleFontWeight;
    const italic = element.styleFontStyle;
    const underlined = element.styleTextDecoration;
    return(
      <Form.Item label="Kiểu chữ" labelAlign='left' className="text-right" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div style={{width: '100%'}}>
          <Button.Group>
            <Button className="btn-style-text" onClick={(e) => this.ChangeValue('bold')} style={stylesheet} type={bold === 'bold' ? "primary" : "default"}><MdFormatBold/></Button>
            <Button className="btn-style-text" onClick={(e) => this.ChangeValue('italic')} style={stylesheet} type={italic === 'italic' ? "primary" : "default"}><MdFormatItalic/></Button>
            <Button className="btn-style-text" onClick={(e) => this.ChangeValue('underline')} style={stylesheet} type={underlined === 'underline' ? "primary" : "default"}><MdFormatUnderlined/></Button>
          </Button.Group>
        </div>
      </Form.Item>
    )
  }
}

export default TextStyle;
