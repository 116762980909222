import React, {
  Component
} from 'react';
import { Form, InputNumber } from 'antd';
import 'antd/dist/antd.css';


class BorderRadius extends Component {
  constructor(props){
    super(props);
    this.state = {
      borderRadiusAll: 0,
      borderRadiusLeftTop: 0,
      borderRadiusRightTop: 0,
      borderRadiusLeftBottom: 0,
      borderRadiusRightBottom: 0,
    }
  }
  ChangeValue(){
    let data = {
      style: {
        all: {
          borderBottomLeftRadius: this.state.borderRadiusLeftBottom,
          borderBottomRightRadius: this.state.borderRadiusRightBottom,
          borderTopLeftRadius: this.state.borderRadiusLeftTop,
          borderTopRightRadius: this.state.borderRadiusRightTop
        }
      },
      itemConfig: {
        all: {
          borderRadiusAll: this.state.borderRadiusAll,
          borderBottomLeftRadius: this.state.borderRadiusLeftBottom,
          borderBottomRightRadius: this.state.borderRadiusRightBottom,
          borderTopLeftRadius: this.state.borderRadiusLeftTop,
          borderTopRightRadius: this.state.borderRadiusRightTop
        }
      }
    };
    this.props.triggerEditPopup(data);
  };

  onChangeBorderRadiusAll = value => {
    this.setState(
      {
        borderRadiusAll: value,
        borderRadiusLeftTop: value,
        borderRadiusRightTop: value,
        borderRadiusLeftBottom: value,
        borderRadiusRightBottom: value
      }, () => this.ChangeValue() // wait for update state finish
    );
  }

  onChangeBorderRadiusLeftTop = value => {
    this.setState(
      { borderRadiusLeftTop: value }, () => this.ChangeValue() // wait for update state finish
    );
  }

  onChangeBorderRadiusRightTop = value => {
    this.setState(
      { borderRadiusRightTop: value }, () => this.ChangeValue() // wait for update state finish
    );
  }

  onChangeBorderRadiusLeftBottom = value => {
    this.setState(
      { borderRadiusLeftBottom: value }, () => this.ChangeValue() // wait for update state finish
    );
  }

  onChangeBorderRadiusRightBottom = value => {
    this.setState(
      { borderRadiusRightBottom: value }, () => this.ChangeValue() // wait for update state finish
    );
  }

  render(){
    const element = this.props.options.itemConfig.all;
    return(
      <div>
        <Form.Item label="Bo góc tất cả" labelAlign='left' className="text-right" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }}>
          <InputNumber
            min={0}
            max={200}
            style={{'width': '100%'}}
            onChange={(e) => this.onChangeBorderRadiusAll(e)}
            value={element.borderRadiusAll ? element.borderRadiusAll : 0 }
          />
        </Form.Item>
        <Form.Item label="Bo góc trái trên" labelAlign='left' className="text-right" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }}>
          <InputNumber
            min={0}
            max={200}
            style={{'width': '100%'}}
            onChange={(e) => this.onChangeBorderRadiusLeftTop(e)}
            value={element.borderTopLeftRadius ? element.borderTopLeftRadius : 0 }
          />
        </Form.Item>
        <Form.Item label="Bo góc phải trên" labelAlign='left' className="text-right" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }}>
          <InputNumber
            min={0}
            max={200}
            style={{'width': '100%'}}
            onChange={(e) => this.onChangeBorderRadiusRightTop(e)}
            value={element.borderTopRightRadius ? element.borderTopRightRadius : 0 }
          />
        </Form.Item>
        <Form.Item label="Bo góc trái dưới" labelAlign='left' className="text-right" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }}>
          <InputNumber
            min={0}
            max={200}
            style={{'width': '100%'}}
            onChange={(e) => this.onChangeBorderRadiusLeftBottom(e)}
            value={element.borderBottomLeftRadius ? element.borderBottomLeftRadius : 0 }
          />
        </Form.Item>
        <Form.Item label="Bo góc phải dưới" labelAlign='left' className="text-right" labelCol={{ span: 16 }} wrapperCol={{ span: 8 }}>
          <InputNumber
            min={0}
            max={200}
            style={{'width': '100%'}}
            onChange={(e) => this.onChangeBorderRadiusRightBottom(e)}
            value={element.borderBottomRightRadius ? element.borderBottomRightRadius : 0 }
          />
        </Form.Item>
      </div>
    )
  }
}

export default BorderRadius;
