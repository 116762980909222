import React, {
  Component
} from 'react';
import ContentEditable from 'react-contenteditable';

class Block extends Component {
  updateContent = result =>{
    let element = this.props.option;
    let data = {
      itemConfig: {
        text: result.target.value
      }
    }
    this.props.changeElement(element, data);
  }

  clickContent(){
    let element = this.props.option;
    this.props.changeElement(element, {});
  }

  render(){
    let data = this.props.option.content.data;
    return(
      <div onClick={(e) => this.clickContent(e)}>
        <div style={{...data.style}}>
          
        </div>
      </div>
    );
  }
}

export default Block;
