import React, {
  Component
} from 'react';
import { Form, Row, Col, Input, Upload, Icon, Button, message } from 'antd';
import 'antd/dist/antd.css';
import { PopupApi } from '../../../../../api/popupApi';
import { OpenWallpaperSidebar } from '../../left/sideLeft';

const imageUrlGlobal = Object;

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Bạn chỉ có thể upload định dạng png hoặc jpg!');
  }
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    message.error('Hình ảnh có dung lượng quá lớn!');
  }
  return isJpgOrPng && isLt5M;
}

const action = PopupApi.API_UPLOAD_IMAGE;

class UploadImage extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      wallpaperSelect: false
    }
  }
  onChange = value => {
    let element = this.props.element;
    let data = {
      itemConfig: {
        imageUrl: value
      }
    };
    this.props.triggerEditElement(element, data);
  };

  UploadImage = image => {
    const { status } = image.file;

    if (status === "uploading"){
      this.setState({
        loading: true
      });
    }else if (status === "done") {
      this.setState({
        loading: false
      });

      this.onChange(image.file.response.src);
      OpenWallpaperSidebar();
    }
  }

  componentDidMount(){
   imageUrlGlobal.url = (data) => {
      this.onChange(data);
      this.setState({
        urlImage: data,
        wallpaperSelect: true
      });
    };
  }

  render(){
    const element = this.props.element.content.data.itemConfig;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const data = {'pid': PopupApi.getId()};
    let node;
    return(
      <div>
        <Form.Item label="Nhập url hình ảnh" className="text-right" labelAlign='left' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Row>
            <Col span={24}>
            <Input
              style={{ width: '100%' }}
              onChange={(e) => this.onChange(e.target.value)}
              value={element.imageUrl}
            />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Tải hình ảnh lên" className="text-right" labelAlign='left' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Row>
            <Col span={24}>
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                onChange={this.UploadImage}
                beforeUpload={beforeUpload}
                data={data}
                action={action}
              >
              {this.state.loading ? uploadButton : element.imageUrl ? <img src={element.imageUrl} alt="Upload image" style={{width: '100%', maxHeight: '150px'}} /> : uploadButton}
              </Upload>
            </Col>
            <Col span={24}>
              <Button onClick={(e) => OpenWallpaperSidebar()} type="primary" style={{width: '100%', height: '35px'}}><Icon type='file-image' />Mở thư viện của bạn</Button>
            </Col>
          </Row>
        </Form.Item>
      </div>
    )
  }
}

const SetUrlImageGlobal = value => {
  imageUrlGlobal.url(value);
}

export {
  SetUrlImageGlobal,
  UploadImage
};
