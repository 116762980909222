import React, {
  Component
} from 'react';
import { Form, Radio } from 'antd';
import 'antd/dist/antd.css';
import { FiAlignLeft, FiAlignCenter, FiAlignRight, FiAlignJustify } from 'react-icons/fi'

class TextAlign extends Component {
  ChangeValue(result){
    let element = this.props.element;
    let data = {
      style: {
        textAlign: result.target.value
      },
      itemConfig: {
        textAlign: result.target.value
      }
    }
    this.props.triggerEditElement(element, data);
  }

  render(){
    let element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Căn lề" labelAlign='left' className="text-right" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <Radio.Group onChange={(e) => this.ChangeValue(e)} defaultValue={element.textAlign} value={element.textAlign} buttonStyle="solid">
          <Radio.Button value="left"><FiAlignLeft/></Radio.Button>
          <Radio.Button value="center"><FiAlignCenter/></Radio.Button>
          <Radio.Button value="right"><FiAlignRight/></Radio.Button>
          <Radio.Button value="justify"><FiAlignJustify/></Radio.Button>
        </Radio.Group>
      </Form.Item>
    )
  }
}

export default TextAlign;
