import React, {
  Component
} from 'react';
import ChangeText from '../forms/changeText';
import FontSize from '../forms/fontSize';
import TextAlign from '../forms/textAlign';
import FontWeight from '../forms/fontWeight';
import FontFamily from '../forms/fontFamily';
import TextStyle from '../forms/textStyle';
import TextColor from '../forms/textColor';
import LineHeight from '../forms/lineHeight';
import TextSpacing from '../forms/textSpacing';
import TextShadow from '../forms/textShadow';
import RemoveElement from '../forms/removeElement';

import { MdArrowDropDown } from "react-icons/md";
import { IconContext } from "react-icons";

class TextLayout extends Component {
  render(){
    return(
      <div className="sidebar-item">
        <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Chỉnh sửa văn bản</span>
        </div>
        <div className="sidebar-content">
          <TextColor triggerEditElement={this.props.triggerEditElement} element={this.props.element}></TextColor>
          <ChangeText triggerEditElement={this.props.triggerEditElement} element={this.props.element}></ChangeText>
          <FontSize triggerEditElement={this.props.triggerEditElement} element={this.props.element}></FontSize>
          <FontWeight triggerEditElement={this.props.triggerEditElement} element={this.props.element}></FontWeight>
          <FontFamily triggerEditElement={this.props.triggerEditElement} element={this.props.element}></FontFamily>
          <TextAlign triggerEditElement={this.props.triggerEditElement} element={this.props.element}></TextAlign>
          <TextStyle triggerEditElement={this.props.triggerEditElement} element={this.props.element}></TextStyle>
          <TextShadow triggerEditElement={this.props.triggerEditElement} element={this.props.element}></TextShadow>
          <LineHeight triggerEditElement={this.props.triggerEditElement} element={this.props.element}></LineHeight>
          <TextSpacing triggerEditElement={this.props.triggerEditElement} element={this.props.element}></TextSpacing>
          <RemoveElement triggerRemoveElement={this.props.triggerRemoveElement} element={this.props.element}></RemoveElement>
        </div>
      </div>
    )
  }
}

export default TextLayout;
