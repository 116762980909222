import React, {
  Component
} from 'react';

const inputStyle = {
  width: '100%',
  border: 'none'
}
class Input extends Component {
  updateContent = result =>{
    let element = this.props.option;
    let data = {
      itemConfig: {
        placeholder: result.target.value
      }
    }
    this.props.changeElement(element, data);
  }

  clickContent(){
    let element = this.props.option;
    this.props.changeElement(element, {});
  }

  render(){
    let data = this.props.option.content.data;
    return(
      <div onClick={(e) => this.clickContent(e)}>
        <input style={{...data.style}} placeholder={data.itemConfig.placeholder} />
      </div>
    );
  }
}

export default Input;
