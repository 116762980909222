import React, {
  Component
} from 'react';
import { Form, Select, Input, Checkbox, Upload, Row, Col, Icon, message } from 'antd';
import { PopupApi } from '../../../../../../api/popupApi';
import 'antd/dist/antd.css';

const { Option } = Select;
const action = PopupApi.API_UPLOAD_FILE;

function beforeUpload(file) {
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error('Tệp tin có dung lượng quá lớn!');
  }
  return isLt10M;
}

class WhenClick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: null,
      loading: false
    }
  }


  changeAction = (e) => {
    this.setState({
      action: e
    });
    e === "close-popup" && this.onChange('', e);
    e === "submit-popup" && this.onChange('', e);
    e === "none" && this.onChange('', e);
  }

  changeLink = (e, action) => {
    this.onChange(e.target.value, action);
  }

  onChange = (link, action) => {
    let element = this.props.element;
    let click;
    try {
      click = this.props.element.content.data.itemConfig.advanced.click;
    } catch (error) {
      click = {};
    }
    let data = {
      itemConfig: {
        advanced: {
          click: {
            ...click,
            action: action,
            link: link
          }
        }
      }
    };
    this.props.triggerEditElement(element, data);
  };

  newTab = (e) => {
    let element = this.props.element;
    let click;
    try {
      click = this.props.element.content.data.itemConfig.advanced.click;
    } catch (error) {
      click = {};
    }
    let data = {
      itemConfig: {
        advanced: {
          click: {
            ...click,
            newTab: e.target.checked
          }
        }
      }
    };

    this.props.triggerEditElement(element, data);
  }

  UploadFile = file => {
    const { status } = file.file;
    if (status === "uploading") {
      this.setState({
        loading: true
      });
    } else if (status === "done") {
      this.setState({
        loading: false
      });

      this.onChange(file.file.response.src, 'download-file');
    }
  }

  render() {
    const advanced = this.props.element.content.data.itemConfig.advanced;
    let click, link, newTab, configAction = this.state.action;

    if (!!advanced) {
      link = advanced.click.link;
      click = advanced.click.action;
      configAction = this.state.action ? this.state.action : click;
      newTab = advanced.click.newTab;
    }



    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const openLink = (
      <div>
        <Form.Item label="Nhập liên kết" labelAlign='left' className="text-right" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Input.TextArea rows={4} onChange={e => this.changeLink(e, 'open-link')} placeholder="Nhập liên kết của bạn vào đây" value={click == "open-link" && link}></Input.TextArea>
        </Form.Item>
        <Form.Item label="Mở liên kết này trong tab mới" labelAlign='left' onChange={e => this.newTab(e)} className="text-right" labelCol={{ span: 20 }} wrapperCol={{ span: 4 }}>
          <Checkbox checked={newTab}></Checkbox>
        </Form.Item>
      </div>
    );

    const downloadFile = (
      <div>
        <Form.Item label="Nhập liên kết tệp tin" labelAlign='left' className="text-right" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Input.TextArea rows={2} onChange={e => this.changeLink(e, 'download-file')} placeholder="Nhập liên kết của bạn vào đây" value={click == "download-file" && link}></Input.TextArea>
        </Form.Item>
        <Form.Item label="Tải tệp tin lên" labelAlign='left' className="text-right" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Row>
            <Col span={24}>
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                onChange={this.UploadFile}
                beforeUpload={beforeUpload}
                action={action}
              >
                {this.state.loading ? uploadButton : uploadButton}
              </Upload>
            </Col>
          </Row>
        </Form.Item>
      </div>
    );
    return (
      <div>
        <Form.Item label="Khi click" labelAlign='left' className="text-right" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Select defaultValue={configAction} value={configAction} onChange={(e) => this.changeAction(e)} style={{ width: '100%' }}>
            <Option value="none">Không</Option>
            <Option value="submit-popup">Gửi thông tin</Option>
            <Option value="close-popup">Đóng Popup</Option>
            <Option value="open-link">Mở Liên kết</Option>
            <Option value="download-file">Tải tệp tin</Option>
          </Select>
        </Form.Item>
        {configAction === "open-link" && openLink}
        {configAction === "download-file" && downloadFile}
      </div>
    )
  }
}

export default WhenClick;
