import { HttpClient } from './lib/httpClient';
import ClientConfig from '../config/ClientConfig';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const HOME_URL = process.env.REACT_APP_BASE_URL + '/popup';
const API_UPLOAD_IMAGE = BASE_URL + '/api/popup/uploadSingleImgByTemplateDesign';
const API_UPLOAD_FILE = BASE_URL + '/api/popup/upload-file'

const getListMedia = () => {
    return HttpClient.get(`${BASE_URL}/popup/media/` + getId() + '?project_token=' + getProjectToken());
}

const getTemplate = () => {;
    return HttpClient.get(`${BASE_URL}/popup/detail/` + getId());
}


const saveTemplate = (params) => {;
    return HttpClient.post(`${BASE_URL}/popup/edit-builder/` + getId(), params);
}

const redirectConfig = () => {
    window.location.href = BASE_URL + '/popup/config/' + + getId();
}

const redirectStep1 = () => {
    window.location.href = window.location.href.replace('/step2','');
}

const redirectStep2 = () => {
    window.location.href += '/step2';
}

const getId = () => {
    try {
        return window.location.pathname.split('/')[2];
    } catch (error) {
        return null;
    }
}

const getSrc = () => {
    try {
        // return window.location.search;
        return window.location.search.split('?img=')[1];
    } catch (error) {
        return null;
    }
}

const getProjectToken = () => {
    return ClientConfig.projectToken;
}

const setProjectToken = (token) => {
    ClientConfig.projectToken = token;
}

const PopupApi = {
    BASE_URL,
    HOME_URL,
    API_UPLOAD_IMAGE,
    API_UPLOAD_FILE,
    getListMedia,
    getTemplate,
    saveTemplate,
    redirectConfig,
    redirectStep1,
    redirectStep2,
    getProjectToken,
    setProjectToken,
    getId,
    getSrc
};

export { PopupApi };
