const TextConfig = {
    text: [
      {
        type: 'text',
        format: 'text_1',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Dancing Script', cursive"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_2',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Indie Flower', cursive"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_3',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Shadows Into Light', cursive"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_4',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Permanent Marker', cursive"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_5',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Caveat', cursive",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_6',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Courgette', cursive",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_7',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Great Vibes', cursive",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_8',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Sacramento', cursive",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_9',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Roboto', cursive",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_10',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'MuseoModerno', cursive"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_11',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Open Sans', sans-serif"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_12',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Lora', sans-serif",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_13',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Inconsolata', sans-serif"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_14',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Source Sans Pro', sans-serif"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_15',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily:"'Nanum Gothic Coding', monospace"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_16',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Cutive Mono', monospace"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_17',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Poppins', sans-serif",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_18',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'Ubuntu', sans-serif",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_19',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'VT323', monospace",
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
      {
        type: 'text',
        format: 'text_20',
        data: {
          style: {
            width: 'auto',
            height: 'auto',
            color: '#fff',
            display: 'block',
            textAlign: 'center',
            fontWeight: 'normal',
            fontSize: '37px',
            fontFamily: "'B612 Mono', monospace"
          },
          itemConfig: {
            width: 'auto',
            fontSize: '36',
            fontWeight: 'normal',
            text: 'BE THE FIRST TO KNOW',
            responsive: {
              mobile: {
                width: 'auto',
                height: 'auto'
              },
              pc: {
                width: 'auto',
                height: 'auto'
              }
            }
          },
        }
      },
    ]
  }
  
  export default TextConfig;
  