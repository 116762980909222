import React, {
  Component
} from 'react';
import { Form, Button, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import { SketchPicker } from 'react-color';

const popover = {
  position: 'relative',
  zIndex: '2',
}
const cover = {
  position: 'absolute',
  top: '5px',
  right: '0px',
  bottom: '0px',
  left: '-45px',
}

class BackgroundColor extends Component {
  constructor(props){
    super(props);
    this.state = {
      toggle: false,
      color: this.props.element.content.data.itemConfig.background ? this.props.element.content.data.itemConfig.background : '#fff'
    }
  }
  openColor(result){
    this.setState({
      toggle: !this.state.toggle
    });
  }

  onChangeTransparent = e => {
    if (e.target.checked){
      this.changeColor('transparent');
    }else{
      this.changeColor(this.state.color);
    }
  }

  changeColor = (color) => {
    let element = this.props.element;
    if (color !== "transparent"){
      this.setState({ color: color });
    }
    let data = {
      style: {
        background: color
      },
      itemConfig: {
        background: color,
        transparent: color === "transparent" ? true : false
      }
    }

    this.props.triggerEditElement(element, data);
  };

  render(){
    const element = this.props.element.content.data.itemConfig;
    return(
      <div>
        <Form.Item label="Màu nền" labelAlign='left' className="text-right" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Button onClick={(e) => this.openColor(e)} type="primary" style={{width: '100%', backgroundColor: element.background, borderColor: element.background}}>{element.background ? element.background : 'Chọn màu'}</Button>
          {this.state.toggle ? <div style={popover}>
            <div style={cover}>
              <SketchPicker color={ element.background } onChange={(e) => this.changeColor(e.hex) }/>
            </div>
          </div> : null}
        </Form.Item>
        <Form.Item label="Không sử dụng nền" labelAlign='left' className="text-right" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
          <Checkbox checked={element.transparent} onChange={this.onChangeTransparent}></Checkbox>
        </Form.Item>
      </div>
    )
  }
}

export default BackgroundColor;
