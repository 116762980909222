import axios from 'axios';

const api_instance = axios.create({
    timeout: 30000,
    withCredentials : true,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});


//Create a Http Client using Axios. Further modifications in this layer can be done later like Authorization.

const post = (url, data, config = {}) => {
    return api_instance.post(url, data, config);
}

const get = (url, data = {}, config = {}) => {
    return api_instance.get(url, data, config);
}

const put = (url, data, config = {}) => {
    return api_instance.put(url, data, config);
}

//Cannot contain a delete method - Cause delete is a keyword.

const del = (url, config = {}) => {
    return api_instance.delete(url, config);
}

//Encapsulating in a JSON object

const HttpClient = {
    post,
    get,
    put,
    delete: del
}

export {HttpClient}
