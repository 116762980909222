import React, {
  Component
} from 'react';
import { Form, Select } from 'antd';
import 'antd/dist/antd.css';

const { Option } = Select;

class FontFamily extends Component {

  changeFontFamily(value){
    let element = this.props.element;
    let data = {
      style: {
        fontFamily: value
      },
      itemConfig: {
        fontFamily: value
      }
    }
    this.props.triggerEditElement(element, data);
  }

  render(){
    let element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Kiểu font" className="text-right" labelAlign='left' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Select onChange={(e) => this.changeFontFamily(e)} value={element.fontFamily ? element.fontFamily : 'Roboto'} style={{width: '100%'}}>
          <Option value="Open sans">Open Sans</Option>
          <Option value="Gelasio">Gelasio</Option>
          <Option value="Roboto">Roboto</Option>
          <Option value="Roboto Mono">Roboto Mono</Option>
          <Option value="Source Sans Pro">Source Sans Pro</Option>
          <Option value="Lora">Lora</Option>
          <Option value="Inconsolata">Inconsolata</Option>
        </Select>
      </Form.Item>
    )
  }
}

export default FontFamily;
