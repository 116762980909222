import React, {
  Component
} from 'react';
import TextLayout from './layout/textLayout';
import ButtonLayout from './layout/buttonLayout';
import BlockLayout from './layout/blockLayout';
import BreakLayout from './layout/breakLayout';
import InputLayout from './layout/inputLayout';
import ImageLayout from './layout/imageLayout';
import StickerLayout from './layout/stickerLayout';
import FilterLayout from './layout/filterLayout';

import PopupConfig from '../../../../config/PopupConfig';
import ArrayHelps from '../../helps/array';

import { changePopupStyle } from '../../content/content';

class AddContentSideRight extends Component {
  triggerEditPopup = (data) => {
    let clone = PopupConfig.popup;
    let res = ArrayHelps.updateOrCreate2(clone, data);
    PopupConfig.popup = res;
    changePopupStyle(res.style);
    // this.forceUpdate();
  }

  render() {
    let option = this.props.element;
    var content = '';
    if (!!!option) return content;
    switch (option.content.type) {
      case 'text':
        content = (
          <TextLayout triggerRemoveElement={this.props.triggerRemoveElement} triggerEditElement={this.props.triggerEditElement} element={option}></TextLayout>
        );
        break;
      case 'image':
        content = (
          <ImageLayout triggerRemoveElement={this.props.triggerRemoveElement} triggerEditElement={this.props.triggerEditElement} element={option}></ImageLayout>
        );
        break;
      case 'button':
        content = (
          <ButtonLayout triggerRemoveElement={this.props.triggerRemoveElement} triggerEditElement={this.props.triggerEditElement} element={option}></ButtonLayout>
        );
        break;
      case 'block':
        content = (
          <BlockLayout triggerRemoveElement={this.props.triggerRemoveElement} triggerEditElement={this.props.triggerEditElement} element={option}></BlockLayout>
        );
        break;
      case 'break':
        content = (
          <BreakLayout triggerRemoveElement={this.props.triggerRemoveElement} triggerEditElement={this.props.triggerEditElement} element={option}></BreakLayout>
        );
        break;
      case 'input':
        content = (
          <InputLayout triggerRemoveElement={this.props.triggerRemoveElement} triggerEditElement={this.props.triggerEditElement} element={option}></InputLayout>
        );
        break;
      case 'sticker':
        content = (
          <StickerLayout triggerRemoveElement={this.props.triggerRemoveElement} triggerEditElement={this.props.triggerEditElement} element={option}></StickerLayout>
        );
        break;
      case 'filter':
        content = (
          <FilterLayout triggerEditPopup={this.triggerEditPopup.bind(this)} triggerEditElement={this.props.triggerEditElement} element={option}></FilterLayout>
        );
        break;
      default:

        break;
    }
    return content;
  }
}

export default AddContentSideRight;
