import React, {
    Component
} from 'react';
import { Form, Button, Select, InputNumber, Slider, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import { SketchPicker } from 'react-color';
const { Option } = Select;
const popover = {
    position: 'relative',
    zIndex: '2',
}
const cover = {
    position: 'absolute',
    top: '5px',
    right: '0px',
    bottom: '0px',
    left: '-45px',
}

const type = {
    padding: '15px 10px',
    boxSizing: 'border-box'
}

class Border extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            color: null,
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1
        }
    }
    openColor(result) {
        this.setState({
            toggle: !this.state.toggle
        });
    }

    changeBorderStyle = style => {
        this.setState(
            { borderStyle: style }, () => this.ChangeValue() // wait for update state finish
        );
    }

    changeBorderColor = (color) => {
        this.setState(
            { borderColor: color.hex }, () => this.ChangeValue() // wait for update state finish
        );
    };

    changeBorderWidth = (width) => {
        this.setState(
            { borderWidth: width }, () => this.ChangeValue() // wait for update state finish
        );
    };

    ChangeValue() {
        let data = {
            style: {
                all: {
                    borderColor: this.state.borderColor,
                    borderStyle: this.state.borderStyle,
                    borderWidth: this.state.borderWidth + 'px',
                    boxSizing: 'border-box'
                }
            },
            itemConfig: {
                all: {
                    borderColor: this.state.borderColor,
                    borderStyle: this.state.borderStyle,
                    borderWidth: this.state.borderWidth
                }
            }
        };
        this.props.triggerEditPopup(data);
    }

    render() {
        const element = this.props.options.itemConfig.all;
        return (
            <div className="border-style">
                <Form.Item label="Đường viền" labelCol={{ span: 24 }}>
                    <Form.Item label="Màu sắc" labelAlign='left' className="text-right" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Button onClick={(e) => this.openColor(e)} type="primary" style={{ width: '100%', backgroundColor: element.borderColor, borderColor: element.borderColor }}>{element.borderColor ? element.borderColor : 'Chọn màu'}</Button>
                        {this.state.toggle ? <div style={popover}>
                            <div style={cover}>
                                <SketchPicker color={element.borderColor} onChange={(e) => this.changeBorderColor(e)} />
                            </div>
                        </div> : null}
                    </Form.Item>
                    <Form.Item label="Kiểu" labelAlign='left' className="text-right" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Select defaultValue={element.borderStyle} value={element.borderStyle} onChange={(e) => this.changeBorderStyle(e)} style={{ width: '100%' }}>
                            <Option style={type} value="solid"><div style={{ border: '1px solid #000' }}></div></Option>
                            <Option style={type} value="dashed"><div style={{ border: '1px dashed #000' }}></div></Option>
                            <Option style={type} value="dotted"><div style={{ border: '1px dotted #000' }}></div></Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Độ rộng" labelAlign='left' className="text-right" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Row>
                            <Col span={17}>
                                <Slider
                                    min={0}
                                    max={50}
                                    onChange={(e) => this.changeBorderWidth(e)}
                                    value={element.borderWidth ? element.borderWidth : 0}
                                />
                            </Col>
                            <Col span={6}>
                                <InputNumber
                                    min={0}
                                    max={50}
                                    className="text-right"
                                    onChange={(e) => this.changeBorderWidth(e)}
                                    style={{ width: '100%', marginLeft: 5 }}
                                    value={element.borderWidth ? element.borderWidth : 0}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Form.Item>
            </div>
        )
    }
}

export default Border;
