import React, {
  Component
} from 'react';
import ChangeText from '../forms/changeText';
import TextColor from '../forms/textColor';
import BackgroundColor from '../forms/bgColor';
import FontSize from '../forms/fontSize';
import FontWeight from '../forms/fontWeight';
import FontFamily from '../forms/fontFamily';
import BoxShadow from '../forms/boxShadow';
import Border from '../forms/border';
import BorderRadius from '../forms/borderRadius';
import TextStyle from '../forms/textStyle';
import Opacity from '../forms/opacity';
import BackgroundGradient from '../forms/bgGradient';
import RemoveElement from '../forms/removeElement';
import { MdArrowDropDown } from "react-icons/md";
import { IconContext } from "react-icons";

class ButtonLayout extends Component {
  render(){
    return(
      <div className="sidebar-item">
        <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Chỉnh sửa nút nhấn</span>
        </div>
        <div className="sidebar-content">
          <TextColor triggerEditElement={this.props.triggerEditElement} element={this.props.element}></TextColor>
          <BackgroundColor triggerEditElement={this.props.triggerEditElement} element={this.props.element}></BackgroundColor>
          <ChangeText triggerEditElement={this.props.triggerEditElement} element={this.props.element}></ChangeText>
          <FontSize triggerEditElement={this.props.triggerEditElement} element={this.props.element}></FontSize>
          <FontWeight triggerEditElement={this.props.triggerEditElement} element={this.props.element}></FontWeight>
          <FontFamily triggerEditElement={this.props.triggerEditElement} element={this.props.element}></FontFamily>
          <TextStyle triggerEditElement={this.props.triggerEditElement} element={this.props.element}></TextStyle>
          <BoxShadow triggerEditElement={this.props.triggerEditElement} element={this.props.element}></BoxShadow>
          <Opacity triggerEditElement={this.props.triggerEditElement} element={this.props.element}></Opacity>
        </div>

        <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Đường viền</span>
        </div>
        <div className="sidebar-content">
          <Border triggerEditElement={this.props.triggerEditElement} element={this.props.element}></Border>
        </div>

        <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Bo tròn góc</span>
        </div>
        <div className="sidebar-content">
          <BorderRadius triggerEditElement={this.props.triggerEditElement} element={this.props.element}></BorderRadius>
        </div>

        <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Trộn màu</span>
        </div>
        <div className="sidebar-content">
          <BackgroundGradient triggerEditElement={this.props.triggerEditElement} element={this.props.element}></BackgroundGradient>
          <RemoveElement triggerRemoveElement={this.props.triggerRemoveElement} element={this.props.element}></RemoveElement>
        </div>

      </div>
    )
  }
}

export default ButtonLayout;
