import React, {
  Component
} from 'react';

import '../side.sass';
import './sideLeft.sass';
import '../../../../constants/style';
import SideBarLeftConfig from '../../../../config/SideBarLeftConfig';
import LayoutWidget from './layoutWidget';
import WallpaperWidget from './wallpaperWidget';
import TextWidget from './textWidget';
import DesignWidget from './designWidget';
import StickerWidget from './stickerWidget';

const wallpaperWidgetGlobal = Object;

class SidebarLeft extends Component {
  constructor(props){
    super(props);
    this.state = {
      sidebar: SideBarLeftConfig,
      activeWidgetId: null
    };
  }

  setActiveMenuWidget = result => {
    this.setState({
      activeWidgetId: result
    });
  }

  render(){
    return(
      <div className="sidebar sidebar-left">
        <div className="sizing flex flex-row h-100">
          <div className="left-bar menu-side-left-bar">
            <ul className="left-bar-items">
              {this.state.id}
              <SideMenuLeftAction SideWidget={this.setActiveMenuWidget} />
            </ul>
          </div>
          <div className="right-bar menu-side-right-bar">
            <div className="right-bar-items">
              <SideMenuLeftWidget  isActiveMenuWidget={this.state.activeWidgetId}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class SideMenuLeftAction extends Component {
  constructor(props){
    super(props);
    this.state = {
      sidebar: SideBarLeftConfig,
      selectedTabId: 2
    };
  }

  isActive(id) {
    return this.state.selectedTabId === id;
  }


  setActiveTab(event, id) {
    this.setState({ selectedTabId: id });
    this.props.SideWidget(id);
  }

  UNSAFE_componentWillMount(){
    this.props.SideWidget(this.state.selectedTabId);
  }

  componentDidMount(){
   wallpaperWidgetGlobal.callback = (data) => {
      this.setState({
        selectedTabId: data
      });
      this.setActiveTab(this, data);
    };
  }


  render() {
    const sidebar = this.state.sidebar.actions;
    return(
      sidebar.map((v, i) => {
        return(
          <li
            className={[this.isActive(v.id) ? "active" : "", "left-bar-item"].join(' ')}
            onClick={(e) => this.setActiveTab(this, v.id)}
            key={i}
          >
            <div className="d-inline-block item__layout">
              { v.icon }
              { this.symbolSideMenuActive() }
            </div>
          </li>
        )
      })
    );
  }
  symbolSideMenuActive() {
    return(<svg
        width="75"
        height="89"
        viewBox="0 0 75 89"
        className="symbolSideMenuActive"
      >
      <defs>
        <filter id="leftBarButtonEarMdShadow" width="200%" height="200%" x="-50%" y="-50%" filterUnits="objectBoundingBox"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"></feOffset><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.581"></feGaussianBlur><feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.0862745098 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.266 0"></feColorMatrix><feMerge><feMergeNode in="shadowMatrixOuter1"></feMergeNode><feMergeNode in="SourceGraphic"></feMergeNode></feMerge>
        </filter>
      </defs>
      <path fill="#FB7D33" d="M66.998 3c-1.534 12.164-8.657 16.407-18.208 16.698-.328-.011-17.954-.013-17.954-.013v.009c-13.179.095-23.837 11.089-23.837 24.261 0 13.232 10.752 23.709 24.012 23.709-4.321 0 14.639.043 14.639.043 9.05.473 19.599 3.744 21.348 19.296V3z" filter="url(#leftBarButtonEarMdShadow)">
      </path>
    </svg>);
  };
}

class SideMenuLeftWidget extends Component {
  render(){
    const isActive = this.props.isActiveMenuWidget;
    return(
      <div className="right-bar-item">
       {isActive === 1 && <LayoutWidget />}
       {isActive === 2 && <TextWidget />}
       {isActive === 3 && <DesignWidget />}
       {isActive === 4 && <StickerWidget />}
       {isActive === 5 && <WallpaperWidget />}
      </div>
    );
  }
}

const OpenWallpaperSidebar = () => {
  wallpaperWidgetGlobal.callback(5);
}



export {
  SidebarLeft,
  OpenWallpaperSidebar
};
