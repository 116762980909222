import React, {
  Component
} from 'react';

const imageDefault = "data:image/jpeg;base64, iVBORw0KGgoAAAANSUhEUgAAAZAAAAEsBAMAAAAfmfjxAAAAG1BMVEXMzMyWlpacnJyqqqrFxcWxsbGjo6O3t7e+vr6He3KoAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAE30lEQVR4nO3dz0/jRhTA8XHixDlmQgkc7YV2OQa6K3F0gG6vSaRyTiRU9phQiTNhteq/3Xkz43gCCXDodkL1/UjYD+xI73k8P2wkUAoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/vc5Z7/xptEF6Xfwyd+G34ujhaRRTW9vdWGs9ehJt8GgO9kuJWibaV+tRVAtbiCTjsqmjDTpyUB9LKPW6gusoplTbQpY2w4e1aIOGPbinqpIO16Komq6QQn9KT3W+Fm2w0B/L71qXUtJeeal7KoyiWtpCOnIzteSy1tEms14pHxn5zVharo6iKmwhTT0129leGG1iO4895bSvZKAYhFFMLT2WQhLXPXphtEFH/2S3uSnp8HkU07CXSCEu8cTc/HW0QWbTTXW3StwUVkdRzQ5tIQt7K7X1PIhEaju2KnwDtewNJIVk7laaHQRRTCYLW8ipn0JGQWSNJci0zzL7U+qTNmj6KWQ/iGK60KUtxF1Pubh1ZDXklmmsdwBpF99kpv3qKKbTA2ULKexw2zHDUR1Zmcx+T8bWpUm94X607AdRRHKTrBWSB5Ez02Wq15L8LpNflX4viCKSJFwhdsxJpZBV5Cz1oBWOSJlbazXcsDbsBVFE4z3lCtE+/W4QOU19OAwnOylkvhqfhzqI4kkla1eIy1sKWUXVOf3TcFLJ3No4cXknOojiabtrq15qEVmkhyNr5tbsu9UidqR5pY/Iyj1f+9TvM1PsbvURO0K9MmpJE8zXP9YyTbRbo5b2+i8V0tHP1l2z/s4WUs3n0yDy2vrZCt0kXc3n/SCKpy6kWmENgshbBI+xv/1hd6ZjVyusvSCKpy5k2+rXKPr1xD50t5HpVru2+lW+s1dPIQ9B5Jin3vGqqsRF5pzqKeQwiCJLgidEHUbOhZ4OVz2/7W4j6Q/+ubAbRnElLz+zm5VvazUjulVXWuzv3jO7L2TrWxS78i2qAbhjX2l9kXJ27i2KXyRte6/VlKQXq/eIhT4v/y6k0XbuvZYvZNubxqXcMo3VOn7hhrlyB980+kKaqze+dSRmLumqy7Rt0nakPV298a2jqJKX3sZnroTZqoUk6Z79Zufexntv/P1I50zv6u9HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD4P0n817v3zgrRvU9bjjwppGu+S7TWSv5FUVe5v5N/WdzKn22/UUmuVPFjM31FN/11y5ENhdht/0Hd+0LSn8tvZnM1KZOvKpv9+Gxf0FUX6uxINcrMBK0PA5VMurJrfhibo8nkSLXn5khQyMFU3fpCmiO3aQ2Se9VexKxDCmndfBk150P1l7q++qySG7u7vpILnJhDnVx9DgvpnnRyX0ijdJs0TxoPj3E7lLm1GmWadwaT+bk6Ntc+Ke3uWA3N0cQcUrfmy/1vEFfIfXvuC0mqTTfJprdxCzGd3V7o/GSaS7KSl+y6VR/pqsem/GH2ukUad+p5i6iPeeQWkUzksk+/TpX8W1eTjuyCFmlM/Im+kNa+et5H1HgUvRDpI2oyvxup+/JSspVd3UdUduBP9IXYb+w2GLVU7ElHEjKjllqWw1JlxYmkI7t61FJZ7k8MC3G336Wu5hEVu5A3aM9fP+dduIudwL8kOYmdAQDgDf4BGWbNg1RynC0AAAAASUVORK5CYII=";

class Image extends Component {
  updateContent = result =>{
    let element = this.props.option;
    let data = {
      itemConfig: {
        text: result.target.value
      }
    }

    this.props.changeElement(element, data);
  }

  clickContent(){
    let element = this.props.option;
    this.props.changeElement(element, {});
  }

  render(){
    let data = this.props.option.content.data;
    return(
      <div onClick={(e) => this.clickContent(e)}>
        <img style={data.style} src={data.itemConfig.imageUrl ? data.itemConfig.imageUrl :  imageDefault} />
      </div>
    );
  }
}

export default Image;
