import React, {
    Component
} from 'react';
import '../../../../constants/style';
import { PopupApi } from '../../../../api/popupApi';
import { SetUrlImageGlobal } from '../right/forms/uploadImage';
import { Icon } from 'antd';
import SideBarLeftConfig from '../../../../config/SideBarLeftConfig';
import StickerConfig from '../../../../config/stickerConfig';
import { Container, Draggable } from 'react-smooth-dnd';
import ArrayHelps from '../../helps/array';

class StickerWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            widget: SideBarLeftConfig
        }
    }

    render() {
        return (
            <div className="p-10">
                <Render dataRender={this.state.widget} />
            </div>
        );
    }
}

class Render extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list: [],
            
        }
    };

    

    render() {
        const loading = (
            <div className="text-center w-100 h-100" style={{ marginTop: '70px' }}>
                <Icon type='loading' style={{ fontSize: '40px', color: '#0884D1' }} />
                <div className="ant-upload-text" style={{ fontSize: '20px', marginTop: '10px', color: '#0884D1' }}>Đang tải</div>
            </div>
        );

        return (
            <div>{this.state.loading ? loading : <ImageList dataRender={this.props.dataRender} />}</div>
        );
    }

    getListMedia = (pid) => {
        return PopupApi.getListMedia(pid).then((res) => {
            return Promise.resolve(res.data);
        });
    }

}


class ImageList extends Component {
    imgEl = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            wallpaperId: 0,
            sidebar: SideBarLeftConfig,
            stickerConfig: StickerConfig
        };
        this.onImgLoad = this.onImgLoad.bind(this);
    }
    onImgLoad = ({ target: img }) => {
        let ratio = img.offsetWidth / img.offsetHeight;
        img.className += " img_vert";
    };

    imageClick = v => {
        SetUrlImageGlobal(v);
    }

    isActive(id) {
        return this.state.wallpaperId === id;
    }

    componentDidMount(){
        var item = this.state.sidebar;
        var positionTypeWidgetInPopup;
        item.widgets.sticker.map((v, i) => {
          positionTypeWidgetInPopup = ArrayHelps.findIndexInData(this.state.stickerConfig.sticker, 'type', 'sticker');
        //   v['icon'] = v.icon;
          return v['content'] = {...this.state.stickerConfig.sticker[positionTypeWidgetInPopup], icon: v.icon};
          
        });
        this.setState({
          sidebar: item
        });
      }

    render() {
        const sticker = this.props.dataRender.widgets.sticker;
        // console.log(this.state.sidebar);
        return (
            <Container
                behaviour="copy"
                groupName="editor"
                getChildPayload={e => this.state.sidebar.widgets.sticker[e]}
            >
                {sticker.map((v, i) => {
                    return (
                        <Draggable className="list-text" style={{ width: '33.333%', background: 'transparent' }}>
                            <img draggable="false" src={v.icon} style={{ height: 'auto' }} className="list-text-item fs-20 shadow-none" />
                        </Draggable>
                    );
                })}
            </Container>
        );
    }
}



export default StickerWidget;
