import React, {
    Component
} from 'react';


class FilterLayout extends Component {
    constructor(props) {
        super(props);
        
    }

    componentWillUpdate() {
        const { style } = this.props.element.content.data;
        let data = {
            style: {
                all: {
                    ...style
                }
            },
            itemConfig: {
                all: {
                    background: '#000'
                }
            }
        }

        this.props.triggerEditPopup(data);
        console.log(123);
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default FilterLayout;
