import React, {
  Component
} from 'react';
import './header.sass';
import '../../../constants/style.js';
import { IoIosCloudDone, IoIosCloudUpload } from "react-icons/io";
import { FaMobileAlt } from "react-icons/fa";
import { MdComputer } from "react-icons/md";
import Element from '../../site/content/element';
import Convert from '../../publish/convert';
import Layout from '../../site/content/layout';
import { PopupApi } from '../../../api/popupApi';

import { Select } from 'antd';

const { Option, OptGroup } = Select;

class Header extends Component {
  publish() {
    Convert.build(Element.init());
  }

  save() {
    Convert.build(Element.init(), 'save');
  }

  constructor(props) {
    super(props);
    this.state = {
      mobile: false
    }
  }

  changeDesignShow = val => {
    this.props.designShow(val);
    if (val === "mobile") {
      this.setState({
        mobile: true
      });
    } else {
      this.setState({
        mobile: false
      });
    }
  }

  render() {
    return (
      <div className="header-builder">
        <nav className="navbar navbar-header navbar-light">
          <div className="nav-sidebar-left pl-20 flex-center">
            <div className="nav-brand">
              <a href={PopupApi.HOME_URL} className="block ml-10">
                <div className="nav-item"><img alt="logo" src={require('../../../storages/img/logo-kc.png')} /></div>
              </a>
            </div>
          </div>
          <div className="nav-sidebar-right flex-center py-3 px-30">
            <div className="nav-icon-top nav-item flex-center" onClick={(e) => this.save(e)}>
              <IoIosCloudDone />
            </div>
            <div className="nav-icon-top nav-item flex-center mr-10" onClick={(e) => this.publish(e)}>
              <IoIosCloudUpload />
            </div>
            <div className="nav-item p-0 flex-center">
              <div className="avatar">
                <img alt="avatar" src="http://vietid.vcmedia.vn/vietid/image/avatars/default.png" />
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
