import ReactDOMServer from 'react-dom/server';
import React from 'react';
import PopupConfig from '../../config/PopupConfig';
import Element from '../site/content/element';
import Layout from '../site/content/layout';
import { PopupApi } from '../../api/popupApi';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import domtoimage from 'dom-to-image';


var cssMobile = [];
var cssPc = [];
var css = [];
var html = [];
const PREFIX_ID = 'SECTION';
const MAX_WIDTH_MOBILE = '655px';
const FONT_FAMILY = '<link href="https://fonts.googleapis.com/css?family=Gelasio:100,200,300,400,500,600,700|Inconsolata:100,200,300,400,500,600,700|Lora:100,200,300,400,500,600,700|Open+Sans:100,200,300,400,500,600,700|Roboto:100,200,300,400,500,600,700|Roboto+Mono:100,200,300,400,500,600,700|Source+Sans+Pro:100,200,300,400,500,600,700&display=swap" rel="stylesheet">';
var Convert = {
    init: function(){
        html.length = 0;
        cssMobile.length = 0;
        cssPc.length = 0;
        css.length = 0;
    },
    build: function(data, type = 'publish'){
        this.init();
        data.forEach(element => {
            html.push(this.convertJsonToHtml(element));
            cssPc.push(this.convertJsonToStylePc(element, element.index));
            cssMobile.push(this.convertJsonToStyleMobile(element, element.index));
        });
        this.export(type);
    },
    convertJsonToHtml: function(element){
        let type = element.content.type;
        let style = element.content.data.style;
        let config = element.content.data.itemConfig;
        let id = PREFIX_ID + element.index;
        let content, action, link, newTab;
        if (config.hasOwnProperty('advanced')) {
            let click = config.advanced.click;
            if (click.hasOwnProperty('action')) { action = click.action; }
            if (click.hasOwnProperty('link')) { link = click.link; }
            if (click.hasOwnProperty('newTab')) { newTab = click.newTab; }
        }

        switch(type){
            case 'button': 
                content = React.createElement('button',{style:{...style}},config.text);
            break;
            case 'image': 
                content = React.createElement('img',{style:{...style}, src: config.imageUrl});
            break;
            case 'text': 
                content = React.createElement('div',{style:{...style}},config.text);
            break;
            case 'block': 
                content = React.createElement('div',{style:{...style}});
            break;
            case 'break': 
                content = React.createElement('div',{style:{...style}});
            break;
            case 'input': 
                content = React.createElement('input',{style:{...style}, placeholder: config.placeholder, name: config.name});
            break;
        }
        let container = React.createElement('div',{style:{position: 'absolute'},clickaction: action, clicklink: link, clicknewtab: JSON.stringify(newTab), id: id}, content);
        return container;
    },

    convertJsonToStyle: function(element, id){
        let style = element.content.data.style;
        let css = '#' + PREFIX_ID + id + '{' + this.JStoCSS(style) + '}';
        return css;
    },

    convertJsonToStylePc: function(element, id){
        let style = element.content.data.itemConfig.responsive.pc;
        let css = '#' + PREFIX_ID + id + '{' + this.JStoCSS(style) + '}';
        return css;
    },
    convertJsonToStyleMobile: function(element, id){
        let style = element.content.data.itemConfig.responsive.mobile;
        let css = '#' + PREFIX_ID + id + '{' + this.JStoCSS(style) + '}';
        return css;
    },

    export: function(type){
        let res = [];
        res['html'] = this.container(html);
        let exportCssAll = '<style>' + css.join('') + '</style>';
        let exportCssPc = '<style>@media screen and (min-width: ' + MAX_WIDTH_MOBILE + ') {' + cssPc.join('') +'}</style>';
        let exportCssMobile = '<style>@media screen and (max-width: ' + MAX_WIDTH_MOBILE + ') {' + cssMobile.join('') +'}</style>';
        
        res['css'] = exportCssAll + exportCssPc + exportCssMobile;
        res['json'] = {
            element: Element.init(),
            layout: Layout.init()
        }
        this.saveTemplateToAPI(res, type);
    },

    container: function(content){
        let style = PopupConfig.popup.style;
        let popup = React.createElement('form',{style:{position: 'relative'}, id: 'popup_content'}, content);
        let cssPopup = '#popup_content{' + this.JStoCSS(style.all) + '}'
        let cssPopupPc = '#popup_content{' + this.JStoCSS(style.pc) + '}';
        let cssPopupMobile = '#popup_content{' + this.JStoCSS(style.mobile) + '}'
        css.push(cssPopup);
        cssMobile.push(cssPopupMobile);
        cssPc.push(cssPopupPc);
        return ReactDOMServer.renderToString(popup);
    },

    JStoCSS: function(JS){
        let cssString = "";
        for (let objectKey in JS) {
            cssString += objectKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ": " + JS[objectKey] + ";";
        }
    
        return cssString; 
    },

    saveTemplateToAPI: function(res, type){
        let data;
        if (Layout.getStep() === 1){
            data = {
                'html': res.html + res.css,
                'json_content': JSON.stringify(res.json),
            };
        }else{
            data = {
                'html_res': res.html + res.css,
                'json_content_res': JSON.stringify(res.json)
            };
        }

        data = {
            ...data,
            'css': FONT_FAMILY
        }
        if (type == 'publish'){
            this.publish(data);
        }else{
            this.save(data);
        }
        
    },

    publish: function(data){
        PopupApi.saveTemplate(data).then(res => {
            if (res.data.success){
                if (Layout.getStep() === 1){
                    Modal.confirm({
                        title: 'Thành công',
                        content: 'Đã lưu thiết kế, bạn có muốn tạo thêm trang cảm ơn?',
                        okText: 'Có',
                        cancelText: 'Không',
                        onOk() {
                            PopupApi.redirectStep2();
                        },
                        onCancel(){
                            PopupApi.redirectConfig();
                        }
                    });
                }else{
                    Modal.success({
                        title: 'Thành công',
                        content: 'Đã lưu thiết kế, chỉ còn 1 bước nữa để hoàn thành việc xuất bản Popup.',
                        okText: 'Đồng ý',
                        onOk() {
                            PopupApi.redirectConfig();
                        }
                    });
                }
            }
        });
    },

    save: function(data){
        // PopupApi.saveTemplate(data).then(res => {
        //     if (res.data.success){
        //         Modal.success({
        //             title: 'Thành công',
        //             content: 'Đã lưu thiết kế thành công!',
        //             okText: 'Đóng',
        //             onOk() {
                        
        //             }
        //         });
        //     }else{
        //         Modal.error({
        //             title: 'Thất bại',
        //             content: 'Có lỗi trong quá trình lưu dữ liệu!',
        //             okText: 'Đóng',
        //             onOk() {
                        
        //             }
        //         });
        //     }
        // });

        // this.convertToBase64(PopupApi.getSrc(), function(myBase64) {
        //     console.log(myBase64); // myBase64 is the base64 string
        // });


        var node = document.getElementById('printscreen');
        domtoimage.toJpeg(node, { quality: 100 })
        .then(function (dataUrl) {
            var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = dataUrl;
        link.click();
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    },

    convertToBase64: function(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
}

export default Convert;