const WallpaperConfig = {
    wallpaper: [
      {
        type: 'filter',
        format: 'blur',
        data: {
          style: {
            filter: 'blur(3px)'
          },
          itemConfig: {
            filter: 'blur(3px)',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      },
      {
        type: 'filter',
        format: 'blen',
        data: {
          style: {
            filter: 'none'
          },
          itemConfig: {
            filter: 'none',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      },
      {
        type: 'filter',
        format: 'brightness',
        data: {
          style: {
            filter: 'brightness(150%)'
          },
          itemConfig: {
            filter: 'brightness(150%)',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      },
      {
        type: 'filter',
        format: 'emboss',
        data: {
          style: {
            filter: 'opacity(70%)'
          },
          itemConfig: {
            filter: 'opacity(70%)',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      },
      {
        type: 'filter',
        format: 'invert',
        data: {
          style: {
            filter: 'hue-rotate(90deg)'
          },
          itemConfig: {
            filter: 'hue-rotate(90deg)',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      },
      {
        type: 'filter',
        format: 'multiply',
        data: {
          style: {
            filter: 'saturate(8)'
          },
          itemConfig: {
            filter: 'saturate(8)',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      },
      {
        type: 'filter',
        format: 'grayscale',
        data: {
          style: {
            filter: 'grayscale(100%)'
          },
          itemConfig: {
            filter: 'grayscale(100%)',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      },
      {
        type: 'filter',
        format: 'grayscale',
        data: {
          style: {
            filter: 'grayscale(100%)'
          },
          itemConfig: {
            filter: 'grayscale(100%)',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      }
    ]
  }
  
  export default WallpaperConfig;
  