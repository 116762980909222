import ArrayHelps from '../helps/array';

var template = {'items': []};

const Element = {
  add: function(element){
    let index = template.items.length;
    var item = {
      index: index,
      content: Object.assign({}, element)
    }

    return this.update(item);
  },

  change: function(element, value){
    let data = element.content.data;
    let index = element.index;

    let clone = ArrayHelps.clone(data);

    let res = ArrayHelps.updateOrCreate(clone, value);
    this.init()[index].content.data = res;

    return this.init();
  },
  remove: function(element){
    let data = this.init();
    let index = element.index;
    data.splice(index, 1);
    for(var i = index;i <= data.length - 1; i++ ){
      data[i].index = data[i].index - 1;
    }
    return data;
  },
  init: function(){
    return template.items;
  },

  update: function(item){
    this.init().push(item);
    return this.init();
  },

  data: function(data){
    template = {
      'items': data
    }
  }
};


export default Element;
