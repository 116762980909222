import React, {
  Component
} from 'react';
import { Form, Row, Col, Input, Slider } from 'antd';
import 'antd/dist/antd.css';

class Height extends Component {
  onChange = value => {
    let configBy, styleBy;
    if (this.props.designInMobile){
      styleBy = {
        mobile: {
          height: value + 'px'
        }
      };
      configBy = {
        mobile: {
          height: value
        }
      }
    }else{
      styleBy = {
        pc: {
          height: value + 'px',
        }
      };
      configBy = {
        pc: {
          height: value
        }
      }
    }

    let data = {
      style: styleBy,
      itemConfig: configBy
    };
    this.props.triggerEditPopup(data);
  };

  render(){
    const element = this.props.options.itemConfig;
    let value = this.props.designInMobile ? element.mobile : element.pc;
    return(
      <Form.Item label="Chiều cao của ảnh" labelAlign='left'>
        <Row>
          <Col span={15}>
            <Slider
              min={10}
              max={1000}
              onChange={this.onChange}
              value={value.height}
            />
          </Col>
          <Col span={8}>
            <Input
              addonAfter="px"
              min={10}
              max={1000}
              className="text-right"
              onChange={(e) => this.onChange(e.target.value.replace(/\D/,''))}
              style={{ width: '100%', marginLeft: 10 }}
              value={value.height}
            />
          </Col>
        </Row>
      </Form.Item>
    )
  }
}

export default Height;
