import React, {
  Component
} from 'react';
import SideBarLeftConfig from '../../../../config/SideBarLeftConfig';
import PopupConfig from '../../../../config/PopupConfig';
import '../../../../constants/style';
import { Container, Draggable } from 'react-smooth-dnd';
import ArrayHelps from '../../helps/array';


class DesignWidget extends Component {
  constructor(props){
    super(props);
    this.state = {
      widget: SideBarLeftConfig
    }
  }
  render(){
    return(
      <div className="p-5">
        <Render designInMobile={this.props.designInMobile} dataRender={this.state.widget}/>
      </div>
    );
  }
}


class Render extends Component {
  constructor(props){
    super(props);
    this.state = {
      sidebar: SideBarLeftConfig,
      popup: PopupConfig
    }
  }


  componentDidMount(){
    var item = this.state.sidebar;
    var positionTypeWidgetInPopup;
    item.widgets.design.map((v, i) => {
      positionTypeWidgetInPopup = ArrayHelps.findIndexInData(this.state.popup.items, 'type', v.type);
      return v['content'] = this.state.popup.items[positionTypeWidgetInPopup];
    });
    this.setState({
      sidebar: item
    });
  }

  render(){
    const widget = this.props.dataRender.widgets.design;
    return(
      <div>
        <Container
          behaviour="copy"
          groupName="editor"
          getChildPayload={e => this.state.sidebar.widgets.design[e]}
        >
        {
          widget.map((v, i) => {
            return(
              <Draggable className="item pd-5" key={i}>
                <div className="btn btn-add-item">
                  <img draggable="false" src={v.icon} />
                  <div className="text">{v.text}</div>
                </div>
              </Draggable>
            );
          })
        }
        </Container>
      </div>
    );
  }
}


export default DesignWidget;
