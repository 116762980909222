import PopupConfig from '../../../config/PopupConfig';
import ArrayHelps from '../helps/array';
var step = 1;

const Layout = {
  init: function(){
    return PopupConfig.popup;
  },
  update: function(data){
    let res = ArrayHelps.updateOrCreate(this.init(), data);
    PopupConfig.popup = res;
  },
  data: function(data){
    PopupConfig.popup = data;
  },
  getStep: function(){
    return step;
  },
  setStep: function(val){
    step = val;
  }
};


export default Layout;
