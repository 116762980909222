import React from 'react';
import { MdInsertEmoticon, MdFormatColorFill, MdEdit } from "react-icons/md";
import { FaBox, FaShapes, FaImage } from "react-icons/fa";
import { BsTextareaT } from "react-icons/bs";

import Text from '../storages/svg/sidebar/text.svg';
import Image from '../storages/svg/sidebar/image.svg';
import Input from '../storages/svg/sidebar/input.svg';
import Button from '../storages/svg/sidebar/button.svg';
import Block from '../storages/svg/sidebar/block.svg';
import Line from '../storages/svg/sidebar/line.svg';

import * as WP from './wallpaperResource';
import * as ST from './stickerResource';

const SideBarLeftConfig = {
  actions: [
    {
      id: 1,
      icon: <MdEdit/>
    },
    {
      id: 2,
      icon: <BsTextareaT/>
    },
    {
      id: 3,
      icon: <FaShapes/>
    },
    {
      id: 4,
      icon: <MdInsertEmoticon/>
    },
    {
      id: 5,
      icon: <FaImage/>
    }
  ],
  widgets: {
    design: [
      {
        icon: Text,
        text: 'Văn bản',
        type: 'text'
      },
      {
        icon: Block,
        text: 'Khối',
        type: 'block'
      },
      {
        icon: Line,
        text: 'Ngắt dòng',
        type: 'break'
      }
    ],
    layout: [
      {

      }
    ],
    text: [
      {
        text: 'Dancing Script',
        type: 'text',
        format: 'text_1',
        font: "'Dancing Script', cursive",
        size: "25px"
      },
      {
        text: 'Indie Flower',
        type: 'text',
        format: 'text_2',
        font: "'Indie Flower', cursive",
        size: "25px"
      },
      {
        text: 'Shadows Into Light',
        type: 'text',
        format: 'text_3',
        font: "'Shadows Into Light', cursive"
      },
      {
        text: 'Permanent Marker',
        type: 'text',
        format: 'text_4',
        font: "'Permanent Marker', cursive"
      },
      {
        text: 'Caveat',
        type: 'text',
        format: 'text_5',
        font: "'Caveat', cursive",
        size: "25px"
      },
      {
        text: 'Courgette',
        type: 'text',
        format: 'text_6',
        font: "'Courgette', cursive",
        size: "25px"
      },
      {
        text: 'Great Vibes',
        type: 'text',
        format: 'text_7',
        font: "'Great Vibes', cursive",
        size: "25px"
      },
      {
        text: 'Sacramento',
        type: 'text',
        format: 'text_8',
        font: "'Sacramento', cursive",
        size: "25px"
      },
      {
        text: 'Roboto',
        type: 'text',
        format: 'text_9',
        font: "'Roboto', cursive",
        size: "25px"
      },
      {
        text: 'Museo Moderno',
        type: 'text',
        format: 'text_10',
        font: "'MuseoModerno', cursive"
      },
      {
        text: 'Open Sans',
        type: 'text',
        format: 'text_11',
        font: "'Open Sans', sans-serif"
      },
      {
        text: 'Lora',
        type: 'text',
        format: 'text_12',
        font: "'Lora', sans-serif",
        size: "25px"
      },
      {
        text: 'Inconsolata',
        type: 'text',
        format: 'text_13',
        font: "'Inconsolata', sans-serif"
      },
      {
        text: 'Source Sans Pro',
        type: 'text',
        format: 'text_14',
        font: "'Source Sans Pro', sans-serif"
      },
      {
        text: 'Nanum Gothic Coding',
        type: 'text',
        format: 'text_15',
        font: "'Nanum Gothic Coding', monospace"
      },
      {
        text: 'Cutive Mono',
        type: 'text',
        format: 'text_16',
        font: "'Cutive Mono', monospace"
      },
      {
        text: 'Poppins',
        type: 'text',
        format: 'text_17',
        font: "'Poppins', sans-serif",
        size: "25px"
      },
      {
        text: 'Ubuntu',
        type: 'text',
        format: 'text_18',
        font: "'Ubuntu', sans-serif",
        size: "25px"
      },
      {
        text: 'VT323',
        type: 'text',
        format: 'text_19',
        font: "'VT323', monospace",
        size: "25px"
      },
      {
        text: 'B612 Mono',
        type: 'text',
        format: 'text_20',
        font: "'B612 Mono', monospace"
      }
    ],
    wallpaper: [
      {
        icon: WP.Blend,
        format: 'blen',
        type: 'filter'
      },
      {
        icon: WP.Blur,
        format: 'blur',
        type: 'filter'
      },
      {
        icon: WP.Brightness,
        format: 'brightness',
        type: 'filter'
      },
      {
        icon: WP.Emboss,
        format: 'emboss',
        type: 'filter'
      },
      {
        icon: WP.Grayscale,
        format: 'grayscale',
        type: 'filter'
      },
      {
        icon: WP.Invert,
        format: 'invert',
        type: 'filter'
      }
      ,
      {
        icon: WP.Multiply,
        format: 'multiply',
        type: 'filter'
      }
    ],
    sticker: [
      {
        icon: ST.Sticker1
      },
      {
        icon: ST.Sticker2
      },
      {
        icon: ST.Sticker3
      },
      {
        icon: ST.Sticker4
      },
      {
        icon: ST.Sticker5
      },
      {
        icon: ST.Sticker6
      },
      {
        icon: ST.Sticker7
      },
      {
        icon: ST.Sticker8
      },
      {
        icon: ST.Sticker9
      },
      {
        icon: ST.Sticker10
      },
      {
        icon: ST.Sticker11
      },
      {
        icon: ST.Sticker12
      },
      {
        icon: ST.Sticker13
      },
      {
        icon: ST.Sticker14
      },
      {
        icon: ST.Sticker15
      },
      {
        icon: ST.Sticker16
      },
      {
        icon: ST.Sticker17
      },
      {
        icon: ST.Sticker18
      },
      {
        icon: ST.Sticker2_1
      },
      {
        icon: ST.Sticker2_2
      },
      {
        icon: ST.Sticker2_3
      },
      {
        icon: ST.Sticker2_4
      },
      {
        icon: ST.Sticker2_5
      },
      {
        icon: ST.Sticker2_6
      },
      {
        icon: ST.Sticker2_7
      },
      {
        icon: ST.Sticker2_8
      },
      {
        icon: ST.Sticker2_9
      },
      {
        icon: ST.Sticker2_10
      },
      {
        icon: ST.Sticker2_11
      },
      {
        icon: ST.Sticker2_12
      },
      {
        icon: ST.Sticker2_13
      },
      {
        icon: ST.Sticker2_14
      },
      {
        icon: ST.Sticker2_15
      },
      {
        icon: ST.Sticker2_16
      },
      {
        icon: ST.Sticker2_17
      },
      {
        icon: ST.Sticker2_18
      },
      {
        icon: ST.Sticker2_19
      },
      {
        icon: ST.Sticker2_20
      },
      {
        icon: ST.Sticker2_21
      },
      {
        icon: ST.Sticker2_22
      },
      {
        icon: ST.Sticker2_23
      },
      {
        icon: ST.Sticker2_24
      }
    ]
  }
};



export default SideBarLeftConfig;
