import React from "react";
import ReactDOM from "react-dom";
import { Resizable } from "re-resizable";

/**
 * React Component cho phép di chuyển 1 phần tử
 * trong phạm vi màn hình.
 * Hiện tại chỉ support di chuyển trên Desktop,
 * chưa support trên điện thoại
 */

 

export class DraggableElement extends React.Component {
  constructor(props) {
    super(props);
    this.dragMouseDown = this.dragMouseDown.bind(this);
    this.elementDrag = this.elementDrag.bind(this);
    this.closeDragElement = this.closeDragElement.bind(this);
    this.mouseHover = this.mouseHover.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.clickEditor = this.clickEditor.bind(this);
    this.clickElement = this.clickElement.bind(this);
    this.state = {
      onResize: false,
      active: this.props.id,
      left: 0,
      top: 0
    }
  }

  /**
   * Hàm này được gọi khi component render xong lên màn hình
   */
  componentDidMount() {
    // Lấy ra DOM node của đối tượng root - toàn bộ component
    this.root = ReactDOM.findDOMNode(this);
    // Lấy ra DOM node của content - vùng chứa nội dung

    this.editor = document.querySelector('#popup .vertical');
    this.content = this.root.querySelector(".lp-draggable-element-item");
    // this.resizable = this.editor.querySelectorAll('span div');
    // console.log(this.content.innerHTML);
    /**
     * Lấy ra DOM node của header - vùng cho phép click chuột
     * để di chuyển
     */
    // this.header = this.root.querySelector(".header");

    /**
     * Tính lại chiều cao cho vùng content,
     * chiều rộng của content luôn = 100% giá trị của root
    //  */
    // const height = this.root.offsetHeight;
    // this.content.style.height = `${height}px`;

    /**
     * Sau đó, thêm scrollbar vào content,
     * nếu nội dung vượt quá kích thước của vùng
     */
    // Đăng ký sự kiện khi người dùng click chuột vào content
 
    this.root.addEventListener("mousedown", this.dragMouseDown);
    this.root.addEventListener("mouseover", this.mouseHover);
    this.root.addEventListener("mouseout", this.mouseOut);
    this.root.addEventListener("click", this.clickElement);
    try {
      this.editor.addEventListener("click", this.clickEditor);
    } catch (error) {
      
    }
    let left = parseInt(this.props.left);
    let top = parseInt(this.props.top);
    
    if (this.props.drop){
      left = left - (this.root.offsetWidth / 2);
      top = top - (this.root.offsetHeight / 2);
      this.setState(
        { left: left + 'px',
          top: top + 'px'}, () => {
          this.updatePositionResponsive();
        } // wait for update state finish
      );
    }
  }

  componentWillUnmount() {
    // Huỷ đăng ký sự kiện khi người dùng click chuột vào content
    this.root.removeEventListener("mousedown", this.dragMouseDown);
  }

  removeLine(){
    this.editor.querySelectorAll('.line').forEach(el => el.remove());
  }

  addLine(){
    var l = document.createElement('div');
    l.className = 'line l-left';
    this.editor.appendChild(l);

    var t = document.createElement('div');
    t.className = 'line l-top';
    this.editor.appendChild(t);

    var r = document.createElement('div');
    r.className = 'line l-right';
    this.editor.appendChild(r);

    var b = document.createElement('div');
    b.className = 'line l-bottom';
    this.editor.appendChild(b);
  }

  resizeable(e){
    let item = e.currentTarget.querySelectorAll('span div');

    item[0].style.borderTop = '1px dashed #0084D1';
    item[1].style.borderRight = '1px dashed #0084D1';
    item[2].style.borderBottom = '1px dashed #0084D1';
    item[3].style.borderLeft = '1px dashed #0084D1';

    item[4].style.background = '#0084D1';
    item[5].style.background = '#0084D1';
    item[6].style.background = '#0084D1';
    item[7].style.background = '#0084D1';
    item.forEach(el => {
      el.style.display = 'block'
    });
  }

  hideResizeable(){
    try{
      
      this.resizable = this.editor.querySelectorAll('span div');
      this.resizable.forEach(el => {
        el.style.display = 'none'
      });
    }catch{

    }
  }

  changePositionLine(left, top){
    this.lineLeft.style.left = left + 'px';
    this.lineTop.style.top = top + 'px';
    this.lineRight.style.left = left + this.root.offsetWidth - 1 + 'px';
    this.lineBottom.style.top = top + this.root.offsetHeight + 'px';
  }

  showRight(){

  }

  number(s){
    try{
      return parseInt(s.match(/\d+/)[0]);
    }catch{
      return parseInt(s);
    }
  }

  draggableSnap(resizable = false){
    var all = document.querySelectorAll('.lp-draggable-element');
    var snap = 5;
    var style = this.root.style;
    var rootLeft = this.number(style.left);
    var rootTop = this.number(style.top);
    var rootRight = this.number(style.left) + this.number(this.root.offsetWidth);
    var rootBottom = this.number(style.top) + this.number(this.root.offsetHeight);
    this.changePositionLine(rootLeft, rootTop);
    all.forEach(el => {
      if (this.root == el) { return; }
      let x = [], y = [];
      x[0] = this.number(el.style.left);
      y[0] = this.number(el.style.top);
      x[1] = this.number(el.style.left) + this.number(el.offsetWidth);
      y[1] = this.number(el.style.top) + this.number(el.offsetHeight);

      
      for(var i = 0; i <= 1;i++){
        if (x[i] - rootLeft <= snap && x[i] - rootLeft >= -snap){
          this.root.style.left = x[i] + 'px';
          this.changePositionLine(x[i], rootTop);
        }
        if (rootRight - x[i] <= snap && rootRight - x[i] >= -snap){
          if (!resizable){
            this.root.style.left = x[i] - parseInt(this.root.offsetWidth) + 'px';
            this.changePositionLine(x[i] - parseInt(this.root.offsetWidth), rootTop);
          }else{
            if (i === 0){
              this.content.style.width = x[0] - rootLeft  + 'px';
            }else{
              this.content.style.width = x[0] - rootLeft + parseInt(el.offsetWidth) + 'px';
            }
            this.changePositionLine(rootLeft, rootTop);
          }
        }
      }
    });
  }
  mouseHover(e){

  }

  mouseOut(e){
   
  }

  clickEditor(e){
    if (e.target !== e.currentTarget) return;
    this.hideResizeable();
  }

  clickElement(e){
    this.hideResizeable();
    this.resizeable(e);
  }

  clone(obj){
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }

  updatePositionResponsive(){
    var responsive = this.props.config.content.data.itemConfig.responsive;
    var data;

    if (this.props.designInMobile){
      data = {
        itemConfig: {
          responsive: {
            mobile: {
              ...responsive.mobile,
              left: this.state.left == 0 ? responsive.mobile.left : this.state.left,
              top: this.state.top == 0 ? responsive.mobile.top : this.state.top,
            },
            pc: {...responsive.pc}
          }
        }
      };
    }else{
      data = {
        itemConfig: {
          responsive: {
            pc: {
              ...responsive.pc,
              left: this.state.left == 0 ? responsive.pc.left : this.state.left,
              top: this.state.top == 0 ? responsive.pc.top : this.state.top,
            },
            mobile: {...responsive.mobile}
          }
        }
      };
    }

    if (responsive.mobile.top == '0px' || !!!responsive.mobile.top) {
      data.itemConfig.responsive.mobile.top = this.state.top;
    }

    if (responsive.mobile.left == '0px' || !!!responsive.mobile.left) {
      data.itemConfig.responsive.mobile.left = this.state.left;
    }

    if (responsive.pc.top == '0px' || !!!responsive.pc.top) {
      data.itemConfig.responsive.pc.top = this.state.top;
    }

    if (responsive.pc.left == '0px' || !!!responsive.pc.left) {
      data.itemConfig.responsive.pc.left = this.state.left;
    }


    this.props.changeElement(this.props.config, data);
  }

  updateSizeResponsive(){
    var responsive = this.props.config.content.data.itemConfig.responsive;
    var width = this.root.offsetWidth + 'px';
    var height = this.root.offsetHeight + 'px';
    var data;
    if (this.props.designInMobile){
      data = {
        itemConfig: {
          responsive: {
            mobile: {
              ...responsive.mobile,
              width: width,
              height: height,
            },
            pc: {...responsive.pc}
          }
        }
      };
    }else{
      data = {
        itemConfig: {
          responsive: {
            pc: {
              ...responsive.pc,
              width: width,
              height: height,
            },
            mobile: {...responsive.mobile}
          }
        }
      };
    }
    this.props.changeElement(this.props.config, data);
  }

  /**
   * Hàm này xử lý khi người dùng click chuột vào header,
   * do đó đối số e - tương ứng với đối tượng MouseEvent
   */
  dragMouseDown(e) {
    this.editor = document.querySelector('#popup .vertical');
    this.editor.addEventListener("click", this.clickEditor);
    // Huỷ bỏ tất cả các xử lý mặc định, nếu có
    e.preventDefault();
    if (e.which !== 1) return;

    /**
     * Lấy ra vị trí click chuột đầu tiên,
     * Mục đích là khi người dùng di chuyển,
     * mình sẽ tính vị trí chuột mới.
     * Sau đó, lấy giá vị trí mới trừ đi giá trị vị trí cũ,
     * sẽ tính được khoảng di chuyển của chuột
     * => cập nhật lại toạ độ cho Component
     */
     this.startX = e.clientX - this.root.offsetLeft;
     this.startY = e.clientY - this.root.offsetTop;


    //clone element
    // this.content = this.elementClone(this.root);

    // Đăng ký sự kiện mousemove, để xử lý khi di chuyển chuột
    this.addLine();
    this.lineLeft = this.editor.querySelector('.l-left');
    this.lineTop = this.editor.querySelector('.l-top');
    this.lineRight = this.editor.querySelector('.l-right');
    this.lineBottom = this.editor.querySelector('.l-bottom');
    window.addEventListener("mousemove", this.elementDrag);
    window.addEventListener("mouseup", this.closeDragElement);
    this.setState({
      // active: this.props.id
    });
  }

  /**
   * Xử lý khi người dùng đã click chuột vào header của component
   * và đang di chuyển => đối số e - là MouseEvent
   */

  onResizeStart(data){
    this.setState({
      onResize: true
    });
  }

  onResizeStop(){
    this.setState({
      onResize: false
    });
    this.updateSizeResponsive();
  }

  onResize(){
    this.draggableSnap(true);
    
  }
  elementClone(e) {
    var clone = e.cloneNode(true);
    var container = e.parentNode;
    clone.style.display = 'none';
    container.appendChild(clone);
    return clone;
  }
  

  elementDrag(e) {
    // Huỷ bỏ tất cả các xử lý mặc định, nếu có
    e.preventDefault();

    if (this.state.onResize) return;

    const left = e.clientX - this.startX;
    const top = e.clientY - this.startY;

    this.root.style.left = `${left}px`;
    this.root.style.top = `${top}px`;
    this.root.style.maxWidth = `maxWidth: '100%'`;
    this.root.style.position = `absolute`;
    this.root.style.display = 'block';
    this.root.style.zIndex = '9999999';
    this.root.style.transform = 'none !important';
    this.setState({
      left: left + 'px',
      top: top + 'px'
    });
    this.changePositionLine(left, top);
    // this.draggableSnap();
  }

  /**
   * Hàm này xử lý khi người dùng nhả chuột - ngừng di chuyển,
   * Mình phải huy các sự kiện mouseup và mousemove đã đăng ký
   */
  closeDragElement() {
    window.removeEventListener("mouseup", this.closeDragElement);
    window.removeEventListener("mousemove", this.elementDrag);
    this.root.style.zIndex = '';
    this.updatePositionResponsive();
    this.removeLine();
  }

  isActive(id) {
    return this.state.drag === id;
  }

  setActive(id){
    this.setState({ drag: id })
  }



  render() {
    const  { config, designInMobile } = this.props; 
    const element = config.content.data.itemConfig;

    var elemStyle = {
      top: designInMobile ? element.responsive.mobile.top : element.responsive.pc.top || 0,
      left: designInMobile ? element.responsive.mobile.left : element.responsive.pc.left || 0,
      position: 'absolute'
    };

    var itemStyle = {
      width: designInMobile ? element.responsive.mobile.width : element.responsive.pc.width || '100%',
      height: designInMobile ? element.responsive.mobile.height : element.responsive.pc.height || 'auto',
    }
    
    const className = this.props.className || "";
    return (
      <div style={elemStyle} className={["lp-draggable-element", className].filter(x => !!x).join(' ')} >
        <Resizable
          size={itemStyle}
          onResizeStop={this.onResizeStop.bind(this)}
          onResizeStart={this.onResizeStart.bind(this)}
          onResize={this.onResize.bind(this)}
          minHeight={1}
          className="lp-draggable-element-item">
          {this.props.children}
        </Resizable>
      </div>
    );
  }
}

export default DraggableElement;
