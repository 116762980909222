import React, {
  Component
} from 'react';
import '../../../../constants/style';
import { PopupApi } from '../../../../api/popupApi';
import { SetUrlImageGlobal } from '../right/forms/uploadImage';
import { Icon } from 'antd';
import SideBarLeftConfig from '../../../../config/SideBarLeftConfig';
import WallpaperConfig from '../../../../config/wallpaperConfig';
import { Container, Draggable } from 'react-smooth-dnd';
import ArrayHelps from '../../helps/array';


class WallpaperWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: SideBarLeftConfig
    }
  }

  render() {
    return (
      <div className="p-10">
        <Render dataRender={this.state.widget} />
      </div>
    );
  }
}

class Render extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: []
    }
  };

  render() {
    const loading = (
      <div className="text-center w-100 h-100" style={{ marginTop: '70px' }}>
        <Icon type='loading' style={{ fontSize: '40px', color: '#0884D1' }} />
        <div className="ant-upload-text" style={{ fontSize: '20px', marginTop: '10px', color: '#0884D1' }}>Đang tải</div>
      </div>
    );

    return (
      <div className="p-relative">{this.state.loading ? loading : <ImageList dataRender={this.props.dataRender} />}</div>
    );
  }
}


class ImageList extends Component {
  imgEl = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      wallpaperId: 0,
      sidebar: SideBarLeftConfig,
      wallpaperConfig: WallpaperConfig
    };
  }


  clickImg = e => {
    alert();
  }

  componentDidMount() {
    var item = this.state.sidebar;
    var positionTypeWidgetInPopup;
    item.widgets.wallpaper.map((v, i) => {
      positionTypeWidgetInPopup = ArrayHelps.findIndexInData(this.state.wallpaperConfig.wallpaper, 'format', v.format);
      return v['content'] = this.state.wallpaperConfig.wallpaper[positionTypeWidgetInPopup];
    });
    this.setState({
      sidebar: item
    });
  }

  render() {
    const widget = this.props.dataRender.widgets.wallpaper;

    return (
      <Container
              behaviour="copy"
              groupName="editor"
              getChildPayload={e => this.state.sidebar.widgets.wallpaper[e]}
            >
              {widget.map((v, i) => {
                return (
                  <Draggable className="list-images" style={{width: '50%'}}>
                    <img draggable="false" onClick={this.clickImg.bind()} onLoad={this.onImgLoad} src={v.icon} className="list-images-item w-100" />
                  </Draggable>
                );
              })}
            </Container>
    );
  }
}



export default WallpaperWidget;
