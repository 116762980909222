import React, {
  Component
} from 'react';
import { Form, Row, Col, Slider } from 'antd';
import 'antd/dist/antd.css';


class Opacity extends Component {
  onChange = value => {
    let opacity = value;
    if (value !== 10){
      opacity = '.' + value;
    }

    let element = this.props.element;
    let data = {
      style: {
        background: 'rgba(0,0,0, ' + opacity + ')'
      },
      itemConfig: {
        backgroundOpacity: value
      }
    };
    this.props.triggerEditElement(element, data);
  };

  render(){
    const element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Độ trong suốt" labelAlign='left'>
        <Row>
          <Col span={24}>
            <Slider
              min={0}
              max={10}
              onChange={this.onChange}
              value={element.backgroundOpacity ? element.backgroundOpacity : 0}
            />
          </Col>
        </Row>
      </Form.Item>
    )
  }
}

export default Opacity;
