import React, {
  Component
} from 'react';
import { Form, Select, Input } from 'antd';
import 'antd/dist/antd.css';

const { Option } = Select;
const selectAfter = (
  <Select onChange={(e) => this.changeType(e)} defaultValue="px" style={{ width: 60 }}>
    <Option value="px">px</Option>
    <Option value="em">em</Option>
    <Option value="rem">rem</Option>
    <Option value="%">%</Option>
  </Select>
);

class FontSize extends Component {
  constructor(props){
    super(props);
    this.state = {
      type: 'px'
    }
  }


  changeText(result){
    let element = this.props.element;
    let data = {
      style: {
        fontSize: result.target.value + 'px'
      },
      itemConfig: {
        fontSize: result.target.value
      }
    }
    this.props.triggerEditElement(element, data);
  }

  render(){
    let element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Font size" labelAlign='left' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Input onChange={(e) => this.changeText(e)} value={element.fontSize} addonAfter={selectAfter} />
      </Form.Item>
    )
  }
}

export default FontSize;
