import React, {
  Component
} from 'react';
import { Form, Row, Col, InputNumber, Slider } from 'antd';
import 'antd/dist/antd.css';


class LineHeight extends Component {
  onChange = value => {
    let element = this.props.element;
    let data = {
      style: {
        lineHeight: value + 'px'
      },
      itemConfig: {
        lineHeight: value
      }
    };
    this.props.triggerEditElement(element, data);
  };

  render(){
    const element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Khoảng cách dòng" labelAlign='left'>
        <Row>
          <Col span={17}>
            <Slider
              min={0}
              max={100}
              onChange={this.onChange}
              value={element.lineHeight ? element.lineHeight : 0}
            />
          </Col>
          <Col span={6}>
            <InputNumber
              min={0}
              max={100}
              className="text-right"
              style={{ width: '100%', marginLeft: 10 }}
              onChange={this.onChange}
              value={element.lineHeight ? element.lineHeight : 0}
            />
          </Col>
        </Row>
      </Form.Item>
    )
  }
}

export default LineHeight;
