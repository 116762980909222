const StickerConfig = {
    sticker: [
      {
        type: 'sticker',
        data: {
          style: {
            width: '100%',
            height: '100%'
          },
          itemConfig: {
            width: '100px',
            height: '100px',
            responsive: {
              mobile: {
                width: '100px',
                height: '100px'
              },
              pc: {
                width: '100px',
                height: '100px'
              }
            },
            advanced: {
              click: {
                action: '',
                link: ''
              }
            }
          }, 
        }
      }
    ]
  }
  
  export default StickerConfig;
  