import React, {
  Component
} from 'react';

import HomeIndex from '../../components/pages/home/index';
import LoadIndex from '../../components/pages/home/load';

class LayoutContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false
    }
  }

  setLoadingSuccess = value => {
    this.setState({
      loading: false
    });
  }

  render(){
    const VIEW = !this.state.loading ? <HomeIndex/> : <LoadIndex loading={this.setLoadingSuccess}/>
    return(VIEW);
  }
}

export default LayoutContainer;
