import React, {
  Component
} from 'react';
import Text from '../popup/text'
import Image from '../popup/image'
import Button from '../popup/button'
import Block from '../popup/block'
import Break from '../popup/break'
import Input from '../popup/input'
import Sticker from '../popup/sticker'

class AddElement extends Component {
  changeElement = (element, data) => {
    this.props.changeElement(element, data);
  }

  render() {
    let option = this.props.element;
    let content = '';
    switch (option.content.type) {
      case 'text':
        content = (
          <Text changeElement={this.changeElement.bind(this)} option={option}></Text>
        );
        break;
      case 'button':
        content = (
          <Button changeElement={this.changeElement.bind(this)} option={option}></Button>
        );
        break;
      case 'image':
        content = (
          <Image changeElement={this.changeElement.bind(this)} option={option}></Image>
        );
        break;
      case 'input':
        content = (
          <Input changeElement={this.changeElement.bind(this)} option={option}></Input>
        );
        break;
      case 'block':
        content = (
          <Block changeElement={this.changeElement.bind(this)} option={option}></Block>
        );
        break;
      case 'break':
        content = (
          <Break changeElement={this.changeElement.bind(this)} option={option}></Break>
        );
        break;
      case 'sticker':
        content = (
          <Sticker changeElement={this.changeElement.bind(this)} option={option}></Sticker>
        );
        break;
      default:

        break;
    }
    return content;
  }
}

export default AddElement;
