import React, {
  Component
} from 'react';
import '../../../../constants/style';

class LayoutWidget extends Component {
  render(){
    return(
      <div className="p-5">
        <div className="flex flex-start flex-wrap">

        </div>
      </div>
    );
  }
}


export default LayoutWidget;
