import React, {
  Component
} from 'react';
import './content.sass';
import { Container } from 'react-smooth-dnd';
import AddElement from './addElement';
import Position from '../../layouts/navigation/positionMouse';
import Layout from './layout';
import DraggableElement from '../../lib/draggable-element/draggable-element'; // The default
import border from '../../../storages/img/border.png';
import BG from '../../../storages/img/bg.jpg';
import { PopupApi } from '../../../api/popupApi';

const popup = Object;

class Content extends Component {
  constructor(props){
    super(props);
    this.state = {
      popupStyle: null,
      drop: false
    }
  }
  onDrop(result){
    this.setState({
      drop: true
    });

    this.props.addElement(result.payload.content);
  }

  addElement(element){
    this.props.addElement(element);
  }

  changeElement(element, data){
    this.props.changeElement(element, data);
  }

  _onMouseMove(e) {
    Position.setX(e.nativeEvent.offsetX);
    Position.setY(e.nativeEvent.offsetY);
  }

  onClickPopupContent(e) {
    if (e.target !== e.currentTarget.querySelector('.smooth-dnd-container')) return;
  }

  componentDidMount(){
   popup.data = style => {
      this.setState({
        popupStyle: style
      });
    };
  }

  render(){
    let items = this.props.items;
    const popupStyle = this.state.popupStyle ? this.state.popupStyle : Layout.init().style;
    const style = this.props.designInMobile ? popupStyle.mobile : popupStyle.pc;
    let mobile = this.props.designInMobile;
    let bg = {
      backgroundImage: `url(${PopupApi.getSrc()})`
    };
    return(
      <div className="content content-type" style={{background: `url(${border})`}}>
        <div className="editor">
          <div id="printscreen" className={[this.props.designInMobile && 'editor-mobile', 'flex-center'].filter(e => !!e).join(' ')}>
            <div className="content-editor" id="popup" onClick={this.onClickPopupContent.bind(this)} onMouseMove={this._onMouseMove.bind(this)} style={{...popupStyle.all, ...style, ...bg}}>
              <Container
                getChildPayload={e => this.props.items[e]}
                onDrop={this.onDrop.bind(this)}
                groupName="editor"
                autoScrollEnabled={false}
              >
                { items.map((v, i) => {
                  return(
                    <DraggableElement
                      designInMobile={mobile}
                      changeElement={this.props.changeElement}
                      config={v}
                      left={Position.getX()}
                      top={Position.getY()}
                      key={i}
                      drop={this.state.drop}
                      >
                      <AddElement changeElement={this.changeElement.bind(this)} key={i} element={v}></AddElement>
                    </DraggableElement>
                  );
                })}
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const changePopupStyle = style => {
  popup.data(style);
}

export {
  Content,
  changePopupStyle
};
