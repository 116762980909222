import React, {
  Component
} from 'react';
import { Form, Select } from 'antd';
import 'antd/dist/antd.css';

const { Option } = Select;

class FontWeight extends Component {
  ChangeValue(result){
    let element = this.props.element;
    let data = {
      style: {
        fontWeight: result
      },
      itemConfig: {
        fontWeight: result
      }
    }
    this.props.triggerEditElement(element, data);
  }

  render(){
    let element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Độ đậm" className="text-right" labelAlign='left' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Select onChange={(e) => this.ChangeValue(e)} value={element.fontWeight} defaultValue={element.fontWeight} style={{width: '100%'}}>
          <Option value="normal">0</Option>
          <Option value="100">100</Option>
          <Option value="200">200</Option>
          <Option value="300">300</Option>
          <Option value="400">400</Option>
          <Option value="500">500</Option>
          <Option value="600">600</Option>
        </Select>
      </Form.Item>
    )
  }
}

export default FontWeight;
