import React, {
    Component
  } from 'react';
  import RemoveElement from '../forms/removeElement';
  import BoxShadow from '../forms/boxShadow';
  import Border from '../forms/border';
  import BorderRadius from '../forms/borderRadius';
  import Opacity from '../forms/opacity';
  import { MdArrowDropDown } from "react-icons/md";
  import { IconContext } from "react-icons";
  
  class StickerLayout extends Component {
    render(){
      return(
        <div className="sidebar-item">
          <div className="sidebar-title">
            <IconContext.Provider value={{className: "icon" }}>
              <div>
                <MdArrowDropDown></MdArrowDropDown>
              </div>
            </IconContext.Provider>
            <span>Chỉnh sửa hình ảnh</span>
          </div>
          <div className="sidebar-content">
            <BoxShadow triggerEditElement={this.props.triggerEditElement} element={this.props.element}></BoxShadow>
            <Opacity triggerEditElement={this.props.triggerEditElement} element={this.props.element}></Opacity>
          </div>
  
          <div className="sidebar-title">
            <IconContext.Provider value={{className: "icon" }}>
              <div>
                <MdArrowDropDown></MdArrowDropDown>
              </div>
            </IconContext.Provider>
            <span>Đường viền</span>
          </div>
          <div className="sidebar-content">
            <Border triggerEditElement={this.props.triggerEditElement} element={this.props.element}></Border>
          </div>
  
          <div className="sidebar-title">
            <IconContext.Provider value={{className: "icon" }}>
              <div>
                <MdArrowDropDown></MdArrowDropDown>
              </div>
            </IconContext.Provider>
            <span>Bo tròn góc</span>
          </div>
          <div className="sidebar-content">
            <BorderRadius triggerEditElement={this.props.triggerEditElement} element={this.props.element}></BorderRadius>
            <RemoveElement triggerRemoveElement={this.props.triggerRemoveElement} element={this.props.element}></RemoveElement>
          </div>
  
        </div>
      )
    }
  }
  
  export default StickerLayout;
  