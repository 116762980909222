import React, {
  Component
} from 'react';
import { Form, Button } from 'antd';
import 'antd/dist/antd.css';
import { SketchPicker } from 'react-color';

const popover = {
  position: 'relative',
  zIndex: '2',
}
const cover = {
  position: 'absolute',
  top: '5px',
  right: '0px',
  bottom: '0px',
  left: '-45px',
}

class TextColor extends Component {
  constructor(props){
    super(props);
    this.state = {
      toggle: false,
      color: null
    }
  }
  openColor(result){
    this.setState({
      toggle: !this.state.toggle
    });
  }

  changeColor = (color) => {
    this.setState({ color: color.hex })
    let element = this.props.element;
    let data = {
      style: {
        color: color.hex
      },
      itemConfig: {
        color: color.hex
      }
    }
    this.props.triggerEditElement(element, data);
  };

  render(){
    const element = this.props.element.content.data.itemConfig;
    return(
      <Form.Item label="Màu chữ" labelAlign='left' className="text-right" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Button onClick={(e) => this.openColor(e)} type="primary" style={{width: '100%', backgroundColor: element.color, borderColor: element.color}}>{element.color ? element.color : 'Chọn màu'}</Button>
        {this.state.toggle ? <div style={popover}>
          <div style={cover}>
            <SketchPicker color={ element.color } onChange={(e) => this.changeColor(e) }/>
          </div>
        </div> : null}
      </Form.Item>
    )
  }
}

export default TextColor;
