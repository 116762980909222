import React, {
  Component
} from 'react';
import { MdArrowDropDown } from "react-icons/md";
import { IconContext } from "react-icons";
import PopupConfig from '../../../../config/PopupConfig';
import ArrayHelps from '../../helps/array';
import Width from './forms/popup-setting/width';
import Height from './forms/popup-setting/height';
import BorderRadius from './forms/popup-setting/borderRadius';
import BackgroundColor from './forms/popup-setting/bgColor';
import BackgroundGradient from './forms/popup-setting/bgGradient';
import BoxShadow from './forms/popup-setting/boxShadow';
import Border from './forms/popup-setting/border';
import { UploadImage } from './forms/popup-setting/uploadImage';
import { changePopupStyle } from '../../content/content';

class PopupSettingSideRight extends Component {
  triggerEditPopup = (data) => {
    let clone = PopupConfig.popup;
    let res = ArrayHelps.updateOrCreate2(clone, data);
    PopupConfig.popup = res;
    changePopupStyle(res.style);
    this.forceUpdate();
  }

  render(){
    let popup = PopupConfig.popup;
    return(
      <div className="sidebar-item">
        <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Chỉnh sửa ảnh nền</span>
        </div>
        <div className="sidebar-content">
          <Width designInMobile={this.props.designInMobile} triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></Width>
          <Height designInMobile={this.props.designInMobile} triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></Height>
          {/* <BackgroundColor triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></BackgroundColor>
          <UploadImage triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></UploadImage> */}
          <BoxShadow triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></BoxShadow>
          <Border triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></Border>
        </div>
        <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Bo tròn góc</span>
        </div>
        <div className="sidebar-content">
          <BorderRadius triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></BorderRadius>
        </div>

        {/* <div className="sidebar-title">
          <IconContext.Provider value={{className: "icon" }}>
            <div>
              <MdArrowDropDown></MdArrowDropDown>
            </div>
          </IconContext.Provider>
          <span>Trộn màu</span>
        </div>
        <div className="sidebar-content">
          <BackgroundGradient triggerEditPopup={this.triggerEditPopup.bind(this)} options={popup}></BackgroundGradient>
        </div> */}

      </div>
    );
  }
}

export default PopupSettingSideRight;
