const PopupConfig = {
  items: [
    {
      type: 'text',
      data: {
        style: {
          width: 'auto',
          height: 'auto',
          color: '#424242',
          display: 'block',
          textAlign: 'center',
          fontWeight: 'normal',
          fontSize: '37px',
          fontFamily: 'Roboto'
        },
        itemConfig: {
          width: 'auto',
          fontSize: '36',
          fontWeight: 'normal',
          text: 'BE THE FIRST TO KNOW',
          responsive: {
            mobile: {
              width: 'auto',
              height: 'auto'
            },
            pc: {
              width: 'auto',
              height: 'auto'
            }
          },
          advanced: {
            click: {
              action: '',
              link: ''
            }
          }
        },
      }
    },
    {
      type: 'image',
      data: {
        style: {
          width: '100%',
          height: '100%'
        },
        itemConfig: {
          width: '250px',
          height: '150px',
          responsive: {
            mobile: {
              width: '250px',
              height: '150px'
            },
            pc: {
              width: '250px',
              height: '150px'
            }
          },
          advanced: {
            click: {
              action: '',
              link: ''
            }
          }
        }, 
      }
    },
    {
      type: 'input',
      data: {
        style: {
          backgroundColor: 'transparent',
          border: '1px solid #ddd',
          fontSize: '16px',
          color: '#333',
          height: '100%',
          width: '100%',
          padding: '0px 20px',
          outline: 'none',
          boxSizing: 'border-box',
          borderRadius: '10px',
        },
        itemConfig: {
          width: '250px',
          fontSize: '16',
          color: '#333',
          placeholder: 'Nhập thông tin của bạn',
          display: 'flex',
          alignItems: 'center',
          height: '50px',
          transparent: true,
          borderRadiusAll: 10,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          responsive: {
            mobile: {
              width: '300px',
              height: '40px'
            },
            pc: {
              width: '250px',
              height: '50px'
            }
          },
          advanced: {
            click: {
              action: '',
              link: ''
            }
          }
        },
      }
    },
    {
      type: 'button',
      data: {
        style: {
          backgroundColor: '#0084D1',
          color: 'white',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '16px',
          borderRadius: '10px',
          width: '100%',
          height: '100%',
          outline: 'none',
          border: 'transparent',
          cursor: 'pointer'
        },
        itemConfig: {
          text: 'Button',
          fontSize: '16',
          fontWeight: '0',
          borderRadiusAll: 10,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          width: '150',
          height: '60',
          SliderGradient1: 0,
          SliderGradient2: 100,
          responsive: {
            mobile: {
              width: '100px',
              height: '40px',
            },
            pc: {
              width: '150px',
              height: '60px'
            }
          },
          advanced: {
            click: {
              action: 'submit-popup',
              link: ''
            }
          }
        },
      }
    },
    {
      type: 'block',
      data: {
        style: {
          background: 'rgba(0,0,0,.4)',
          width: '100%',
          height: '100%',
          minWidth: '1px',
          minHeight: '1px'
        },
        itemConfig: {
          width: '200',
          height: '150',
          backgroundOpacity: 4,
          responsive: {
            mobile: {
              width: '350px',
              height: '150px'
            },
            pc: {
              width: '200px',
              height: '150px'
            }
          },
          advanced: {
            click: {
              action: '',
              link: ''
            }
          }
        },
      }
    },
    {
      type: 'break',
      data: {
        style: {
          backgroundColor: '#0084D1',
          width: '100%',
          height: '100%',
          minWidth: '1px',
          minHeight: '1px'
        },
        itemConfig: {
          width: '200',
          height: '4',
          backgroundColor: '#0084D1',
          responsive: {
            mobile: {
              width: '350px',
              height: '4px'
            },
            pc: {
              width: '200px',
              height: '4px'
            }
          },
          advanced: {
            click: {
              action: '',
              link: ''
            }
          }
        },
      }
    }
  ],
  popup: {
    style: {
      pc: {
        width: '650px',
        height: '450px',
        overflow: 'hidden'
      },
      mobile: {
        width: '100%',
        height: '300px',
        maxWidth: '350px',
        overflow: 'hidden'
      },
      all: {
        borderRadius: '0px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        boxShadow: '#9b9b9b 0px 0px 6px',
        fontFamily: 'Roboto'
      }
    },
    itemConfig: {
      pc: {
        width: 650,
        height: 450,
      },
      mobile: {
        width: 100,
        height: 300,
      },
      all: {
        borderRadius: '0px',
        backgroundColor: '#fff',
        borderRadiusAll: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadowColor: '#9b9b9b',
        boxShadowSlider: 6
      }
    }
  },
  text: [
    {
      type: 'text',
      format: 'text_1',
      data: {
        style: {
          width: 'auto',
          height: 'auto',
          color: '#fff',
          display: 'block',
          textAlign: 'center',
          fontWeight: 'normal',
          fontSize: '37px',
          fontFamily: "'Dancing Script', cursive"
        },
        itemConfig: {
          width: 'auto',
          fontSize: '36',
          fontWeight: 'normal',
          text: 'BE THE FIRST TO KNOW',
          responsive: {
            mobile: {
              width: 'auto',
              height: 'auto'
            },
            pc: {
              width: 'auto',
              height: 'auto'
            }
          }
        },
      }
    }
  ]
}

export default PopupConfig;
