import React, {
  Component
} from 'react';
import { Form, Row, Col, Slider, Button } from 'antd';
import 'antd/dist/antd.css';
import { SketchPicker } from 'react-color';


const popover = {
  position: 'relative',
  zIndex: '2',
}
const cover = {
  position: 'absolute',
  top: '5px',
  right: '0px',
  bottom: '0px',
  left: '-95px',
}

const initState = {
  toggleColor1: null,
  toggleColor2: null,
  color1: '#FFF',
  color2: '#000',
  slider1: 0,
  slider2: 100,
  positionGradient: 0
}

class BackgroundGradient extends Component {
  constructor(props){
    super(props);
    this.state = initState;
  }

  openColor(result, id){
    if (id === 1){
      this.setState({
        toggleColor1: !this.state.toggleColor1
      });
    }else {
      this.setState({
        toggleColor2: !this.state.toggleColor2
      });
    }
  }

  changeColor1 = (color) => {
    this.setState(
      { color1: color.hex }, () => this.ChangeValue() // wait for update state finish
    );
  }

  changeColor2 = (color) => {
    this.setState(
      { color2: color.hex }, () => this.ChangeValue() // wait for update state finish
    );
  }

  changeSlider1 = (value) => {
    this.setState(
      { slider1: value }, () => this.ChangeValue() // wait for update state finish
    );
  }

  changeSlider2 = (value) => {
    this.setState(
      { slider2: value }, () => this.ChangeValue() // wait for update state finish
    );
  }

  onChangePositionColor = (value) => {
    this.setState(
      { positionGradient: value }, () => this.ChangeValue() // wait for update state finish
    );
  }

  ChangeValue = () => {
    let element = this.props.element;
    let data = {
      style: {
        backgroundImage: 'linear-gradient(' + this.state.positionGradient + 'deg, ' + this.state.color1 + ' ' + this.state.slider1 + '%, ' + this.state.color2 +  ' ' + this.state.slider2 + '%)'
      },
      itemConfig: {
        ColorGradient1: this.state.color1,
        ColorGradient2: this.state.color2,
        SliderGradient1: this.state.slider1,
        SliderGradient2: this.state.slider2,
        PositionGradient: this.state.positionGradient
      }
    }
    this.props.triggerEditElement(element, data);
  };

  render(){
    const element = this.props.element.content.data.itemConfig;

    return(
      <div>
        <Form.Item label="Màu thứ nhất" labelAlign='left'>
          <Row>
            <Col span={13}>
              <Slider
                min={0}
                max={100}
                onChange={(e) => this.changeSlider1(e)}
                value={element.SliderGradient1 ? element.SliderGradient1 : 0}
              />
            </Col>
            <Col span={10}>
              <Button onClick={(e) => this.openColor(e, 1)} type="primary" style={{width: '100%', marginLeft: 10, backgroundColor: element.ColorGradient1, borderColor: element.ColorGradient1}}  className="text-right">{element.ColorGradient1 ? element.ColorGradient1 : 'Chọn màu'}</Button>
              {this.state.toggleColor1 ? <div style={popover}>
                <div style={cover}>
                  <SketchPicker color={ element.ColorGradient1 } onChange={(e) => this.changeColor1(e) }/>
                </div>
              </div> : null}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Màu thứ hai" labelAlign='left'>
          <Row>
            <Col span={13}>
              <Slider
                min={0}
                max={100}
                onChange={(e) => this.changeSlider2(e)}
                value={element.SliderGradient2 ? element.SliderGradient2 : 100}
              />
            </Col>
            <Col span={10}>
              <Button onClick={(e) => this.openColor(e, 2)} type="primary" style={{width: '100%', marginLeft: 10, backgroundColor: element.ColorGradient2, borderColor: element.ColorGradient2}} className="text-right">{element.ColorGradient2 ? element.ColorGradient2 : 'Chọn màu'}</Button>
              {this.state.toggleColor2 ? <div style={popover}>
                <div style={cover}>
                  <SketchPicker color={ element.ColorGradient2 } onChange={(e) => this.changeColor2(e) }/>
                </div>
              </div> : null}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Vị trí màu sắc" labelAlign='left'>
          <Row>
            <Col span={24}>
              <Slider
                min={0}
                max={360}
                onChange={(e) => this.onChangePositionColor(e)}
                value={element.positionGradient ? element.positionGradient : this.state.positionGradient}
              />
            </Col>
          </Row>
        </Form.Item>
      </div>
    )
  }
}

export default BackgroundGradient;
